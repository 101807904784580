import { Timecodes } from './components/Timecodes';
import { CardList, Inner, Title, VideoWrapper } from './VideoPage.styled';

import Container from '../../components/Shared/Container/Container';
import FullScreenLoader from '../../components/Shared/FullScreenLo/FullScreenLoader';

import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
// eslint-disable-next-line import/named
import YouTube, { YouTubeEvent, YouTubeProps } from 'react-youtube';

import { videosAPI, playlistsAPI } from '@/api';
import { VideoFragmentCard } from '@/components/Card';
import SearchInVideoInput from '@/components/Shared/SearchInVideoInput/SearchInVideoInput';
import { useAppSelector, useHandlingError, useActions, useMatchMedia } from '@/hooks';

const VideoPage = () => {
  const { t } = useTranslation();
  const { id, playlistId } = useParams();
  const [params, setParams] = useSearchParams();
  const [currentTime, setCurrentTime] = useState(null);
  const { catchError } = useHandlingError();
  const isTablet = useMatchMedia('(max-width: 768px)');

  const isAuth = useAppSelector((state) => state.user.isAuth);

  const { showNotification } = useActions();

  const {
    data: video,
    isLoading,
    error: videoError,
  } = videosAPI.useGetMovieByIdQuery({ id: id ?? '' }, { skip: !isAuth });

  const [getSearchVideos, { data: searchVideos, isLoading: isSearchLoading, error: searchError }] =
    playlistsAPI.useLazyGetFullSearchQuery();

  const getSearchVideosHandler = useCallback(
    async (query: string) => {
      await getSearchVideos({ query, publicId: playlistId || '' });
    },
    [playlistId],
  );

  useEffect(() => {
    if (!isAuth) {
      showNotification({
        text: t('w_video', { ns: 'error' }),
        severity: 'error',
      });
      setParams((params) => {
        params.set('popup', 'login');
        return params;
      });
    }
  }, [isAuth]);

  useEffect(() => {
    catchError(videoError || searchError);
  }, [videoError, searchError]);

  const iframe = useRef<YouTube>(null);
  const iframeWrapper = useRef<HTMLDivElement>(null);
  const vkRef = useRef<HTMLIFrameElement>(null);

  const getCurrentTimeFunc = async () => {
    setCurrentTime((await iframe.current?.internalPlayer.getCurrentTime()) || 0);
  };

  let timerId: number;
  const onStateChange: YouTubeProps['onStateChange'] = (event) => {
    if (event.data === 1) {
      timerId = setInterval(() => {
        getCurrentTimeFunc();
      }, 1000);
    } else if (event.data === 2) {
      clearInterval(timerId);
    }
  };

  const height = isTablet ? '300px' : '500px';

  const goToTimeFunc = async (event: YouTubeEvent) => {
    await event.target.seekTo(params.get('t') ?? 0, true);
    await event.target.playVideo();
  };

  const goToTime = useCallback(
    (time: number) => {
      if (video && video.source === 'VK' && vkRef.current) {
        // TODO разобраться с типизацией
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const player = window.VK.VideoPlayer(vkRef.current);
        player.seek(time);

        iframeWrapper.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        return;
      }

      iframe.current?.internalPlayer.seekTo(time, true);
      iframeWrapper.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
    [video],
  );

  const startsForm = useMemo(() => {
    const time = params.get('t');
    return time ? parseInt(time) : 0;
  }, [params]);

  return (
    <Container>
      {isLoading && <FullScreenLoader />}
      {video && (
        <Inner>
          <VideoWrapper ref={iframeWrapper}>
            {video.source === 'YOUTUBE' && (
              <YouTube
                videoId={video.videoId}
                title={video.title}
                ref={iframe}
                style={{ width: '100%', height }}
                onStateChange={onStateChange}
                onReady={goToTimeFunc}
                opts={{
                  height,
                  width: '100%',
                  playerVars: {
                    start: video.startsFrom || startsForm,
                    autoplay: 1,
                    rel: 0,
                  },
                }}
              />
            )}

            {video.source === 'VK' && (
              <iframe
                ref={vkRef}
                title={video.title}
                src={`${video.originLink}&hd=2&autoplay=14&t=${video.startsFrom || startsForm}s&js_api=1`}
                width="100%"
                height="500px"
                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
              ></iframe>
            )}
          </VideoWrapper>
          <Title>{video.title}</Title>

          <div>
            {playlistId && (
              <>
                {isAuth && <SearchInVideoInput getSearch={getSearchVideosHandler} />}
                {searchVideos && (
                  <CardList>
                    {searchVideos &&
                      searchVideos.map((fragment) =>
                        fragment.cues.map((cue, i) => {
                          if (fragment.publicId === video.publicId) {
                            return (
                              <VideoFragmentCard
                                fragment={cue}
                                key={fragment.publicId + i}
                                goToTime={goToTime}
                                videoPreview={fragment.thumbnailUrl}
                              />
                            );
                          }
                        }),
                      )}
                  </CardList>
                )}
                {isSearchLoading && <FullScreenLoader />}
              </>
            )}
          </div>
          <Timecodes playlistId={playlistId || ''} id={id || ''} setTime={goToTime} currentTime={currentTime} />
        </Inner>
      )}
    </Container>
  );
};

export default memo(VideoPage);
