import styled from 'styled-components';

import { LabelText, theme } from '@/styles';

export const PlayListImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin-bottom: 20px;
  background-color: ${theme.colors.blue.blue_25};
`;

export const Form = styled.form`
  position: relative;
  width: 434px;
  height: 100%;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
`;

export const SourcesList = styled.ul`
  display: flex;
  gap: 30px;
  align-items: center;
`;

export const SourcesItem = styled.li``;

export const SourcesLink = styled.a``;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;

  & button {
    @media screen and (max-width: 370px) {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

export const ExitButtonWrapper = styled.button`
  position: absolute;
  top: 0;
  right: 0;
`;

export const SuggestVideoListWrapper = styled.div`
  height: 600px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 8px; /* ширина scrollbar */
  }
  ::-webkit-scrollbar-track {
    background: transparent; /* цвет дорожки */
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px; /* закругления плашки */
    background-color: ${theme.colors.blue.blue_5}; /* цвет плашки */
  }
`;

export const SuggestVideoList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  padding-right: 5px;
`;

export const SuggestVideoItem = styled.li`
  padding: 30px;
  border-radius: 20px;
  background-color: ${theme.colors.blue.blue_25};
  width: 100%;
  height: 254px;
`;

export const SuggestVideoCardStyled = styled.div`
  max-width: 436px;
  width: 100%;
  position: relative;
  cursor: pointer;
`;

export const SuggestVideoCardImage = styled.div<{ thumbnailUrl: string }>`
  background-image: url(${(props) => props.thumbnailUrl});
  background-position: center;
  background-size: cover;
  max-width: 376px;
  width: 100%;
  height: 166px;
  border-radius: 20px;
`;

export const SuggestVideoCardTitle = styled(LabelText)`
  margin-top: 16px;
  line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  text-align: center;

  user-select: none;
`;
