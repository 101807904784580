import { memo } from 'react';

import { VideoCard, VideoCardProps } from '@/components/Card';
import { CardsList } from '@/styles';
import { Video, VideoInPlaylist } from '@/types';

interface VideoListProps<T> {
  videos: T[];
  isLoading?: boolean;
  videoSettings?: Pick<VideoCardProps, 'withTime' | 'menu' | 'isPrivate'>;
}

export const VideoList = memo(<T extends Video | VideoInPlaylist>({ videos, videoSettings }: VideoListProps<T>) => {
  return (
    <CardsList>
      {videos.map((video) => (
        <VideoCard
          key={video.publicId}
          movie={video}
          to={`watch/${video.publicId}?t=${video.startsFrom ?? 0}`}
          {...videoSettings}
        />
      ))}
    </CardsList>
  );
});

VideoList.displayName = 'VideoList';
