const SignInIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M22 11.9895L10.2353 11.9895M10.2353 11.9895L13.5882 8.66667M10.2353 11.9895L13.5882 15.3333M17.7556 6.44444C17.2672 3.30397 15.5149 2 12.0141 2L11.8918 2C7.68471 2 6 3.88025 6 8.57563L6 15.4244C6 20.1197 7.6847 22 11.8918 22L12.0141 22C15.5149 22 17.2692 20.6975 17.7581 17.5556"
      stroke="#E4E4FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SignInIcon;
