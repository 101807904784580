import styled, { css } from 'styled-components';

import { Button } from '@/components';
import { theme } from '@/styles';

export const ArrowsStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 20px;
`;

export const ArrowButton = styled(Button)`
  ${({ disabled }) =>
    !disabled &&
    css`
      :hover {
        path {
          transition: all 0.5s ease;
          stroke: ${theme.colors.white.white_80};
        }
      }
      :active {
        path {
          transition: all 0.5s ease;
          stroke: ${theme.colors.blue.blue_dark};
        }
      }
    `}
`;
