import { SVGProps } from '../svg.props';

export interface LockIconProps extends SVGProps {
  size?: 's' | 'm';
  theme?: 'primary' | 'outline';
  variant?: 'lock' | 'unlock';
}

// TODO: fix size 's'

export const LockIcon = ({ size = 'm', variant = 'lock', theme = 'primary', ...props }: LockIconProps) => {
  const sizeMap = {
    s: { width: '14', height: '14', viewBox: '0 0 14 14' },
    m: { width: '18', height: '20', viewBox: '0 0 18 20' },
  };

  if (variant === 'unlock') {
    return (
      <svg {...sizeMap[size]} fill="none" xmlns="https://www.w3.org/2000/svg" {...props}>
        {theme === 'primary' ? (
          <>
            <path
              d="M13 6V5C13 2.79086 11.2091 1 9 1V1C6.79086 1 5 2.79086 5 5V6"
              stroke="#E4E4FF"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.87868 5.87868C0 6.75736 0 8.17157 0 11V12C0 15.7712 0 17.6569 1.17157 18.8284C2.34315 20 4.22876 20 8 20H10C13.7712 20 15.6569 20 16.8284 18.8284C18 17.6569 18 15.7712 18 12V11C18 8.17157 18 6.75736 17.1213 5.87868C16.2426 5 14.8284 5 12 5H6C3.17157 5 1.75736 5 0.87868 5.87868ZM9 13C9.55228 13 10 12.5523 10 12C10 11.4477 9.55228 11 9 11C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13ZM12 12C12 13.3062 11.1652 14.4175 10 14.8293V17H8V14.8293C6.83481 14.4175 6 13.3062 6 12C6 10.3431 7.34315 9 9 9C10.6569 9 12 10.3431 12 12Z"
              fill="#E4E4FF"
            />
          </>
        ) : (
          <>
            <path
              d="M11.5 6.99981V6.99981C11.8043 5.17359 11.0108 3.33842 9.47168 2.30935L9.09795 2.05946C7.07338 0.705785 4.3937 0.872632 2.55269 2.46699L1.66992 3.23149"
              stroke="#E4E4FF"
              strokeWidth="2"
              strokeLinecap="round"
            />

            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.87868 0.87868C0 1.75736 0 3.17157 0 6V7C0 10.7712 0 12.6569 1.17157 13.8284C2.34315 15 4.22876 15 8 15H10C13.7712 15 15.6569 15 16.8284 13.8284C18 12.6569 18 10.7712 18 7V6C18 3.17157 18 1.75736 17.1213 0.87868C16.2426 0 14.8284 0 12 0H6C3.17157 0 1.75736 0 0.87868 0.87868ZM9 8C9.55228 8 10 7.55228 10 7C10 6.44772 9.55228 6 9 6C8.44772 6 8 6.44772 8 7C8 7.55228 8.44772 8 9 8ZM12 7C12 8.30622 11.1652 9.41746 10 9.82929V12H8V9.82929C6.83481 9.41746 6 8.30622 6 7C6 5.34315 7.34315 4 9 4C10.6569 4 12 5.34315 12 7Z"
              fill="#E4E4FF"
            />
          </>
        )}
      </svg>
    );
  }

  return (
    <svg {...sizeMap[size]} fill="none" xmlns="https://www.w3.org/2000/svg" {...props}>
      {theme === 'primary' ? (
        <>
          <path
            d="M13 6V5C13 2.79086 11.2091 1 9 1V1C6.79086 1 5 2.79086 5 5V6"
            stroke="#E4E4FF"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.87868 5.87868C0 6.75736 0 8.17157 0 11V12C0 15.7712 0 17.6569 1.17157 18.8284C2.34315 20 4.22876 20 8 20H10C13.7712 20 15.6569 20 16.8284 18.8284C18 17.6569 18 15.7712 18 12V11C18 8.17157 18 6.75736 17.1213 5.87868C16.2426 5 14.8284 5 12 5H6C3.17157 5 1.75736 5 0.87868 5.87868ZM9 13C9.55228 13 10 12.5523 10 12C10 11.4477 9.55228 11 9 11C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13ZM12 12C12 13.3062 11.1652 14.4175 10 14.8293V17H8V14.8293C6.83481 14.4175 6 13.3062 6 12C6 10.3431 7.34315 9 9 9C10.6569 9 12 10.3431 12 12Z"
            fill="#E4E4FF"
          />
        </>
      ) : (
        <>
          <path
            d="M1 11C1 9.11438 1 8.17157 1.58579 7.58579C2.17157 7 3.11438 7 5 7H13C14.8856 7 15.8284 7 16.4142 7.58579C17 8.17157 17 9.11438 17 11V13C17 15.8284 17 17.2426 16.1213 18.1213C15.2426 19 13.8284 19 11 19H7C4.17157 19 2.75736 19 1.87868 18.1213C1 17.2426 1 15.8284 1 13V11Z"
            stroke="#E4E4FF"
            strokeWidth="2"
          />
          <path
            d="M13 6V5C13 2.79086 11.2091 1 9 1V1C6.79086 1 5 2.79086 5 5V6"
            stroke="#E4E4FF"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <circle cx="9" cy="13" r="2" fill="#E4E4FF" />
        </>
      )}
    </svg>
  );
};
