import { ButtonsWrapper, Form, Label, PlayListImage } from './CreatePlaylist.styled';
import { usePlaylist } from './hooks';

import Input from '../../Input/Input';
import { Inner, Modal, Title } from '../Modal';

import { FormEvent, memo } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Alert, Collapse, IconButton } from '@mui/material';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { playlistsAPI } from '@/api';
import { Button, FullScreenLoader } from '@/components';
import { SecondaryText, SelectWrapper } from '@/styles';

export const CreatePlaylist = memo(() => {
  const { t } = useTranslation('modal');

  const [createPL, result] = playlistsAPI.useCreatePlaylistMutation();

  const {
    playlist,
    categories,
    errorAlert,
    setErrorAlert,
    categoryOptions,
    privacyOptions,
    titleInputHandler,
    descriptionInputHandler,
    selectCategoryHandler,
    selectAccessHandler,
    closeHandler,
    resetError,
    showNotification,
    errorCreatePlaylist,
    catchError,
  } = usePlaylist();

  const createPlaylist = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (playlist.title) {
      const payload = await createPL(playlist);

      if ('data' in payload) {
        closeHandler();
        showNotification({
          text: t('cr_pl', { ns: 'success', name: playlist.title }) as string,
          severity: 'success',
        });
        ReactGA.event({ category: 'playlist', action: 'add_playlist' });
      } else {
        errorCreatePlaylist(payload.error);
        catchError(payload);
      }
    } else {
      setErrorAlert({
        open: true,
        message: t('req_field', { ns: 'error' }) as string,
      });
    }
  };

  const currentCategory = categories.find((el) => el.publicId === playlist.category);

  return (
    <Modal modal={'AddPlayList'} onClose={() => closeHandler()} closeButton>
      <Title>{t('createPlaylist', { ns: 'header' })}</Title>
      <Inner modal={'AddPlayList'}>
        <Form onSubmit={createPlaylist}>
          <Collapse in={errorAlert.open}>
            <Alert
              severity="error"
              variant="filled"
              action={
                <IconButton aria-label="close" color="inherit" size="small" onClick={resetError}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {errorAlert.message}
            </Alert>
          </Collapse>
          {categories.length > 0 && (
            <PlayListImage
              src={currentCategory?.image ?? '/images/playlist_mock.png'}
              alt={currentCategory?.name ?? ''}
            />
          )}

          <Label>
            <SecondaryText>{t('category')}</SecondaryText>
            <SelectWrapper>
              <Select
                id="selectId"
                className="basic-single"
                classNamePrefix="select"
                options={categoryOptions}
                defaultValue={categoryOptions.find((el) => el.value === playlist.category)}
                isDisabled={false}
                // isLoading={Boolean(selectIsLoading)}
                isSearchable={false}
                name="color"
                onChange={selectCategoryHandler}
              />
            </SelectWrapper>
          </Label>
          <Label>
            <SecondaryText>{`${t('pl_name')} *`}</SecondaryText>
            <Input value={playlist.title} onChange={titleInputHandler} placeholder={t('pl_name_ph')} />
          </Label>
          <Label>
            <SecondaryText>{t('descr')}</SecondaryText>
            <Input
              value={playlist.description}
              onChange={descriptionInputHandler}
              placeholder={t('descr_ph')}
              type="textarea"
              maxLength={5000}
              rows={3}
              wrap="soft"
            />
          </Label>
          <Label>
            <SecondaryText>{t('priv_set')}</SecondaryText>
            <SelectWrapper>
              <Select
                id="selectAccess"
                className="basic-single"
                classNamePrefix="select"
                options={privacyOptions}
                defaultValue={privacyOptions[0]}
                isDisabled={false}
                // isLoading={Boolean(selectIsLoading)}
                isSearchable={false}
                name="color"
                menuPlacement="top"
                onChange={selectAccessHandler}
              />
            </SelectWrapper>
          </Label>
          <ButtonsWrapper>
            <Button theme="white" type="submit">
              {t('form.save', { ns: 'components' })}
            </Button>
            <Button onClick={() => closeHandler()} type="button" theme="inline">
              {t('form.cancel', { ns: 'components' })}
            </Button>
          </ButtonsWrapper>
          {result.isLoading && <FullScreenLoader />}
        </Form>
      </Inner>
    </Modal>
  );
});

CreatePlaylist.displayName = 'CreatePlaylist';
