const ShareIcon = () => (
  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M5.9133 1.65579L5.79038 3.47437L5.77826 3.71557L5.72377 3.72135C3.03641 4.02214 1 6.2109 1 8.89133C1 9.15567 1.02059 9.4177 1.06176 9.67625C1.23071 10.7434 1.35787 10.847 2.13477 9.96431C2.32731 9.74963 2.53793 9.55037 2.76452 9.36853C3.57771 8.72643 4.58902 8.357 5.64323 8.31695L5.78008 8.31406L5.81702 9.02495L5.91754 10.3814C5.96598 10.8464 6.47826 11.164 6.94937 10.91C7.16251 10.7955 7.37021 10.6729 7.57912 10.5514C8.66121 9.90473 9.67427 9.16145 10.6613 8.38925C11.2426 7.92651 11.7512 7.48807 12.1848 7.08895C12.3846 6.90212 12.5845 6.71529 12.7776 6.52209C13.0574 6.24214 13.0852 5.77882 12.7922 5.48555C12.1176 4.81169 11.1645 3.96776 9.97825 3.07236C9.01666 2.35395 8.02237 1.68298 6.96874 1.09356C6.46191 0.817654 5.95932 1.19594 5.9133 1.65579V1.65579Z"
      stroke="#BBB6DA"
    />
  </svg>
);

export default ShareIcon;
