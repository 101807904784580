import { Main } from '../../../App.styled';
import { Footer } from '../../../components';
import Container from '../../../components/Shared/Container/Container';
import Header from '../Header';
import { ResultWrapper } from '../Search.styled';

import { Outlet } from 'react-router-dom';

import { useMatchMedia } from '@/hooks';

const ResultsLayout = () => {
  const isMobile = useMatchMedia('(max-width: 1000px)');

  return (
    <ResultWrapper isMobile={isMobile}>
      <Header />
      <Main>
        <Container>
          <Outlet />
        </Container>
      </Main>
      <Footer />
    </ResultWrapper>
  );
};

export default ResultsLayout;
