import { Title, Wrapper, Description, Content } from './NotFoundPage.styled';

import GoHomeButton from '../../components/Shared/Buttons/GoHomeButton/GoHomeButton';

import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
  const { t } = useTranslation('pages');

  return (
    <Wrapper>
      <Content>
        <Title>{t('404.ops')}</Title>
        <Description>{t('404.info')}</Description>
        <GoHomeButton />
      </Content>
    </Wrapper>
  );
};

export default NotFoundPage;
