const LikeIcon = () => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M8.465 13.1868C8.21 13.2711 7.79 13.2711 7.535 13.1868C5.36 12.4916 0.5 9.59129 0.5 4.67556C0.5 2.50562 2.3675 0.75 4.67 0.75C6.035 0.75 7.2425 1.36798 8 2.32303C8.38533 1.83559 8.88723 1.43941 9.4655 1.16625C10.0438 0.893091 10.6823 0.750533 11.33 0.75C13.6325 0.75 15.5 2.50562 15.5 4.67556C15.5 9.59129 10.64 12.4916 8.465 13.1868Z"
      stroke="#BBB6DA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LikeIcon;
