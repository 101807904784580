import styled from 'styled-components';

import { theme } from '@/styles';

export const ContentWrapper = styled.div`
  @media (min-width: 576px) {
    margin-top: 13vh;
    padding-left: 40px;
  }

  @media (min-width: 993px) {
    padding-left: 8vw;
    padding-right: 3vw;
  }

  @media (min-width: 1400px) {
    padding-left: 15vw;
    padding-right: 18vh;
  }
`;

export const TextWrapper = styled.div`
  line-height: 140%;
  margin-bottom: 15px;

  @media (min-width: 996px) {
    margin-bottom: 30px;
  }
`;

export const Header = styled.h1`
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 52px;
  line-height: 130%;
  color: ${theme.colors.white.white_100};
  font-family: ${theme.fonts.nunito};

  @media (max-width: 992px) {
    font-size: 26px;
  }
`;

export const TextBlock = styled.p`
  font-weight: 400;
  font-size: 26px;
  line-height: 170%;
  color: ${theme.colors.label.label_light};
  font-family: ${theme.fonts.nunito};

  @media (max-width: 993px) {
    font-size: 16px;
  }
`;

export const TextSpan = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 34px;
  color: ${theme.colors.label.label_light};
  font-family: ${theme.fonts.nunito};

  @media (min-width: 993px) {
    font-size: 20px;
  }

  @media (min-width: 1200px) {
    padding-left: 5vh;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  gap: 15px;

  @media (min-width: 993px) {
    gap: 20px;
  }
`;

export const Icon = styled.img`
  display: flex;
  gap: 15px;
  max-width: 69px;

  @media (min-width: 768px) {
    gap: 20px;
    width: auto;
  }
`;

export const SectionContent = styled.div`
  @media (min-width: 576px) {
    margin: 0 auto;
    width: max-content;
  }
`;

export const ImgSlide = styled.img`
  display: block;
  padding-top: 50px;
  padding-bottom: 20px;
  max-width: 90vw;

  @media (min-width: 576px) {
    padding-top: 0;
  }

  @media (min-width: 993px) {
    width: 45%;
    padding-top: 34px;
    padding-bottom: 0;
  }
`;
export const SpanWrapper = styled.div``;
export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 100px;

  @media (min-width: 576px) {
    margin-bottom: 70px;
  }

  @media (min-width: 992px) {
    gap: 12px;
  }

  @media (min-width: 1400px) {
    gap: 210px;
  }
`;
