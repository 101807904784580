export const generateBreadcrumbs = (t: any, pathnames: any) => {
  if (pathnames.includes('playlistview')) {
    return [
      { label: t('breadcrumbsItems.toHome'), path: '/' },
      { label: t('breadcrumbsItems.toPl'), path: `/${pathnames[0]}/${pathnames[1]}` },
    ];
  } else if (pathnames.includes('playlist')) {
    return [
      { label: t('breadcrumbsItems.toLib'), path: '/library' },
      { label: t('breadcrumbsItems.toPl'), path: `/${pathnames[0]}/${pathnames[1]}` },
    ];
  } else if (pathnames.includes('quiz')) {
    return [
      { label: t('breadcrumbsItems.toLib'), path: '/library' },
      { label: t('breadcrumbsItems.toPl'), path: `/playlist/${pathnames[1]}` },
      { label: t('breadcrumbsItems.toVideo'), path: `quiz/${pathnames[1]}` },
    ];
  } else {
    return [
      { label: t('breadcrumbsItems.toLib'), path: '/' },
      { label: t('breadcrumbsItems.toPl'), path: pathnames },
      { label: t('breadcrumbsItems.toVideo'), path: pathnames },
    ];
  }
};
