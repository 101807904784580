const LanguageIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 2.05C13 2.05 16 6 16 12C16 18 13 21.95 13 21.95M11 21.95C11 21.95 8 18 8 12C8 6 11 2.05 11 2.05M2.63 15.5H21.37M2.63 8.5H21.37M2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12Z"
      stroke="#E4E4FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LanguageIcon;
