import { DescriptionText, TitleH5 } from '../../../styles/components';
import { theme } from '../../../styles/theme';
import { PlaylistCardStyleProps } from '../PlaylistCard';

import styled from 'styled-components';

export const PlaylistStatCardStyled = styled.div`
  display: flex;
  gap: 20px;
`;

export const StatImageWrapper = styled.div<PlaylistCardStyleProps>`
  background-color: ${theme.colors.blue.blue_20};
  background-image: url(${(props) => props.bgImage});
  background-size: cover;
  background-position: center center;
  border-radius: 20px;
  height: 80px;
  width: 115px;
  flex-shrink: 0;
  cursor: pointer;
  @media screen and (max-width: 480px) {
    border-radius: 10px;
    height: 50px;
    width: 70px;
  }
`;

export const StatInner = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  flex-direction: row;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const InnerText = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
  overflow: hidden;
  width: 100%;
`;

export const StatTitle = styled(TitleH5)`
  hyphens: manual;
  overflow: hidden;
  text-overflow: ellipsis; /* Добавляем многоточие */
  /* white-space: nowrap; //Запрещаем перенос строк */
  max-height: 24px;
`;

export const Description = styled.div`
  display: flex;
  max-width: 825px;
  width: 100%;
  /* height: 18px; */
  overflow: auto; /* Обрезаем все, что не помещается в область */
`;

export const StatSubTitle = styled(DescriptionText)`
  color: ${theme.colors.text.white_80};

  hyphens: auto;
  text-overflow: ellipsis; /* Добавляем многоточие */
  //white-space: nowrap; //Запрещаем перенос строк
  max-height: 42px;
`;

export const StatsList = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px 15px;
`;

export const StatsItem = styled.li`
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  user-select: none;
  color: ${theme.colors.text.white_80};
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 5px;
  @media screen and (max-width: 1024px) {
    justify-content: flex-start;
  }
`;
export const StatisticsItemText = styled(DescriptionText)``;

export const StatisticsItemIcon = styled(DescriptionText)`
  display: none;
  text-transform: lowercase;
  @media screen and (max-width: 1024px) {
    display: flex;
  }
`;
