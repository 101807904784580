import { Wrapper } from './SharePrivateButton.styled';

import ShareIconBig from '../../../SvgIcons/ShareIconBig';

import { CircularProgress } from '@mui/material';

import { ButtonText } from '@/styles';

interface ShareButtonProps {
  onClick: () => void;
  content?: string;
  isLoading?: boolean;
}

const ShareButton = ({ onClick, content, isLoading = false }: ShareButtonProps) => {
  return (
    <Wrapper onClick={onClick} isLoading={isLoading} disabled={isLoading}>
      {isLoading ? <CircularProgress size={'24px'} color="inherit" /> : <ShareIconBig />}
      <ButtonText>{isLoading ? 'Получение ссылки' : content}</ButtonText>
    </Wrapper>
  );
};

export default ShareButton;
