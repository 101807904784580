import { AvatarWrapper, AvatarMenuArrow } from '../../../components/Header/AvatarMenu/AvatarWithMenu.styled';
import DropDown from '../../../components/Shared/DropDown';

import styled from 'styled-components';

export const SortWrapper = styled(AvatarWrapper)<{ open: boolean }>`
  margin-left: 0;

  @media (max-width: 1024px) {
    margin-left: 0;
  }
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

export const SortMenuArrow = styled(AvatarMenuArrow)``;

export const SortDropDown = styled(DropDown)`
  margin-bottom: 20px;
  & div {
    &:last-child {
      left: 0;
      width: max-content;
    }
  }
`;
