import { SecondaryText, theme } from '../../../../styles';

import styled, { css } from 'styled-components';

type PropType = {
  active?: boolean;
};

export const Inner = styled.div<PropType>`
  position: relative;
  display: flex;

  width: 493px;
`;

export const Form = styled.form`
  width: 493px;
`;
export const Label = styled.label`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
`;

export const SourcesList = styled.ul`
  display: flex;
  gap: 30px;
  align-items: center;
`;

export const SourcesItem = styled.li``;

export const SourcesLink = styled.a``;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;

export const ExitButtonWrapper = styled.button`
  position: absolute;
  top: 0;
  right: 0;
`;

export const VideosList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;
  max-height: 394px;
  padding-right: 5px;
  animation: skale-in 0.3s;

  @keyframes skale-in {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  ::-webkit-scrollbar {
    width: 10px; /* ширина scrollbar */
  }
  ::-webkit-scrollbar-track {
    background: transparent; /* цвет дорожки */
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px; /* закругления плашки */
    background-color: ${theme.colors.blue.blue_5}; /* цвет плашки */
  }
  @media screen and (max-height: 725px) {
    div[id='modal'] {
      height: 100% !important;
    }
  }
`;
export const VideosListItem = styled.li<PropType>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;
export const CheckBox = styled.div<{ defaultChecked: boolean }>`
  cursor: pointer;
  display: inline-block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 2px solid ${theme.colors.white.white_100};
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all ease 0.3s;

  ${(props) =>
    props.defaultChecked &&
    css`
      border-color: ${theme.colors.blue.blue_70};
      background-color: ${theme.colors.blue.blue_70};
      /* background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='https://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.655386 5.63773C0.855463 5.4474 1.17195 5.45531 1.36227 5.65539L4.69168 9.15539C4.88201 9.35546 4.8741 9.67195 4.67403 9.86227C4.47395 10.0526 4.15747 10.0447 3.96714 9.84462L0.637729 6.34462C0.447404 6.14454 0.455309 5.82805 0.655386 5.63773Z' fill='%23E4E4FF'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.345 2.13803C11.5449 2.32853 11.5525 2.64503 11.362 2.84493L4.69144 9.84493C4.50093 10.0448 4.18444 10.0525 3.98453 9.86197C3.78463 9.67147 3.777 9.35498 3.9675 9.15507L10.6381 2.15507C10.8286 1.95516 11.1451 1.94753 11.345 2.13803Z' fill='%23E4E4FF'/%3e%3c/svg%3e"); */
      background-image: url('/images/check.svg');
    `}
`;

export const LabelBox = styled.div``;

export const VideoImage = styled.img`
  width: 96px;
  height: 94px;
  border-radius: 20px;
  object-fit: cover;
  object-position: center;
`;

export const VideosWrapper = styled.div`
  width: 493px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  user-select: none;
  min-height: 394px;
  align-items: center;
  justify-content: center;
`;

export const InputContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
export const VideoTitle = styled(SecondaryText)``;
