import { theme } from '../../styles';
import { Header } from '../LibraryPage';

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr max-content;
  height: 100%;
  @media screen and (max-width: 1024px) {
    grid-template-rows: max-content max-content 1fr max-content;
  }
`;

export const StatHeader = styled(Header)`
  margin-bottom: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Limit = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  height: 100%;

  @media screen and (max-width: 1100px) {
    gap: 5px;
    font-size: 15px;
  }
`;
export const LimitList = styled.ul`
  display: flex;
  flex-direction: row;
  border: 1px solid ${theme.colors.blue.blue_25};
  border-radius: 4px;
`;

export const LimitItem = styled.li<{ active?: boolean }>`
  padding: 6px 8px 5px;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  text-align: center;

  background: ${(props) => (props.active ? theme.colors.blue.blue_25 : '')};
  user-select: none;
  cursor: pointer;

  &:nth-child(n-1) {
    border: 1px solid ${theme.colors.blue.blue_25};
  }
  @media screen and (max-width: 1100px) {
    gap: 5px;
    font-size: 13px;
    line-height: 17px;
    padding: 3px 4px 3px;
  }
`;

export const StateFooter = styled.div``;
