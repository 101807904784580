import { ButtonsWrapper, Form, Info } from './Registration.styled';

import Button from '../../Buttons/Button/Button';
import FullScreenLoader from '../../FullScreenLo/FullScreenLoader';
import MyInput from '../../MyInput/MyInput';
import { FieldsWrapper, ToRegistrationButton } from '../Login/Login.styled';
import { InnerAuth, Modal, Title, OtherText } from '../Modal';

import { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertTitle, Collapse, IconButton } from '@mui/material';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import ReactGA from 'react-ga4';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { usersAPI } from '@/api';
import { errorsRegistration } from '@/components/Shared/Modals/Registration/helpers/errorsRegistration';
import { useActions, useAppSearchParams } from '@/hooks';
import { RegisterUser } from '@/types';

export const Registration = () => {
  const { closeModal, openModal } = useActions();
  const { setAppParams, deleteAppParam } = useAppSearchParams();
  const [errorAlertIsOpen, setErrorAlertIsOpen] = useState<boolean>(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState<string>('');
  const { t, i18n } = useTranslation('error');

  const [userRegister, statusReg] = usersAPI.useRegisterUserMutation();
  // const [userResetPassword, statusResetPassword] = usersAPI.useResetPasswordMutation();
  // const [userRegister, statusReg] = api.useRegisterUserMutation();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    // setError,
  } = useForm<RegisterUser>();

  const closeHandler = () => {
    closeModal();
    reset();
    setErrorAlertIsOpen(false);
    deleteAppParam('popup');
  };

  const toggleRegistration = () => {
    setAppParams('popup', 'login');
  };

  const onSubmit = async (data: RegisterUser) => {
    try {
      await userRegister({ ...data }).unwrap();

      ReactGA.gtag('event', 'sign_up', {
        method: 'site',
      });
      openModal({ active: 'verifyinfo', data: { email: data.email } });
    } catch (error) {
      const authError = error as FetchBaseQueryError;
      if (authError.data) {
        const errorMessages = errorsRegistration(authError, t);
        const message = errorMessages.join('\n\n');
        setErrorAlertMessage(message);
        setErrorAlertIsOpen(true);
      }
    }
  };

  const resetErrors = () => {
    setErrorAlertIsOpen(false);
  };

  return (
    <Modal modal={'registration'} onClose={closeHandler} closeButton>
      <Title>{t('menu.up', { ns: 'header' })}</Title>
      <InnerAuth modal={'registration'}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Collapse in={errorAlertIsOpen}>
            <Alert
              severity="error"
              variant="filled"
              action={
                <IconButton aria-label="close" color="inherit" size="small" onClick={resetErrors}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              <AlertTitle>{t('reg')}</AlertTitle>
              {errorAlertMessage}
            </Alert>
          </Collapse>
          <FieldsWrapper>
            <MyInput
              required
              label={t('form.name', { ns: 'components' })}
              {...register('username', {
                required: { value: true, message: t('req_field') },
                minLength: { value: 3, message: t('min_char', { length: 3 }) },
                maxLength: { value: 256, message: t('max_char', { length: 256 }) },
              })}
              placeholder={t('form.name', { ns: 'components' })}
              error={errors.username}
            />
            <MyInput
              required
              label={t('form.email', { ns: 'components' })}
              {...register('email', {
                required: { value: true, message: t('req_field') },
                minLength: { value: 5, message: t('min_char', { length: 3 }) },
                maxLength: { value: 256, message: t('max_char', { length: 256 }) },
                pattern: {
                  value: /^([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*\.[a-z]{2,6}$/,
                  message: t('incor_email'),
                },
              })}
              placeholder={t('form.email', { ns: 'components' })}
              error={errors.email}
            />
            <MyInput
              required
              label={t('form.password', { ns: 'components' })}
              type="password"
              {...register('password', {
                required: { value: true, message: t('req_field') },
                minLength: { value: 6, message: t('min_char', { length: 6 }) },
                maxLength: { value: 128, message: t('max_char', { length: 128 }) },
                pattern: {
                  value: /^(?=.*[a-zA-Z])(?=.*\d).{6,128}$/,
                  message: t('incor_password'),
                },
              })}
              placeholder={t('form.password', { ns: 'components' })}
              error={errors.password}
            />
          </FieldsWrapper>
          <ButtonsWrapper>
            <Button theme={'white'} type={'submit'}>
              {t('reg', { ns: 'modal' })}
            </Button>
            <Button theme="inline" type="reset" onClick={closeHandler}>
              {t('form.cancel', { ns: 'components' })}
            </Button>
          </ButtonsWrapper>
          <Info>
            {`${t('reg_info', { ns: 'modal' })} `}
            {i18n.language === 'ru' ? (
              <>
                <NavLink to={'policy/personal'} onClick={closeHandler}>
                  пользовательское соглашение
                </NavLink>
                {' и '}
                <NavLink to={'policy/privacy'} onClick={closeHandler}>
                  политику конфиденциальности
                </NavLink>
              </>
            ) : (
              <NavLink to={'policy/privacy'} onClick={closeHandler}>
                Public Privacy Policy
              </NavLink>
            )}
          </Info>
          <ToRegistrationButton onClick={toggleRegistration}>
            <OtherText>{t('reg_no_acc', { ns: 'modal' })}</OtherText>
            <OtherText style={{ textDecoration: 'underline' }}>{t('menu.in', { ns: 'header' })}</OtherText>
          </ToRegistrationButton>
        </Form>
        {statusReg.isLoading && <FullScreenLoader />}
      </InnerAuth>
    </Modal>
  );
};

export default Registration;
