import { theme } from '../../../../styles';

import styled from 'styled-components';

export const Inner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const ExitButtonWrapper = styled.div`
  margin-left: auto;
  width: max-content;
`;

export const Button = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 10px 0;

  p {
    color: ${theme.colors.text.white_100};
  }

  /* svg {
    path {
      fill: ${theme.colors.white.white_100};
    }
  } */
`;
