import { useEffect, useState } from 'react';

// const queries = ['(max-width: 767px)', '(min-width:768px) and (max-width: 1024px)', '(min-width: 1025px)'];

export function useMatchMedia(query: string): boolean {
  const [matches, setMatches] = useState<boolean>(window.matchMedia(query).matches);

  // cDM, cDU
  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    media.addEventListener('change', listener);
    return () => media.removeEventListener('change', listener);
  }, [query]);

  return matches;
}
