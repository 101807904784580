import {
  Copy,
  Inner,
  FooterStyled,
  Info,
  LinksWrapper,
  InfoSection,
  FooterContainer,
  InfoImage,
  TargetsSection,
  TargetsText,
  TargetsItem,
  InfoTitle,
} from './Footer.styled';
import Targets from './Targets/Targets';

import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useMatchMedia } from '@/hooks';

const Footer = () => {
  const getYear = useMemo(() => new Date().getFullYear(), []);
  const { t, i18n } = useTranslation('pages');
  const isMobile = useMatchMedia('(max-width: 1000px)');

  return (
    <FooterStyled>
      <FooterContainer>
        <InfoSection>
          <InfoTitle>{t('search.footer.title')}</InfoTitle>
          {isMobile ? (
            <Targets prop={t} />
          ) : (
            <TargetsSection>
              <TargetsItem>
                <InfoImage image="/images/target-1.svg" />
                {/* <img src="/images/target-1.svg" /> */}
                <TargetsText>{t('search.footer.target')}</TargetsText>
              </TargetsItem>
              <TargetsItem>
                <InfoImage image="/images/target-2.svg" />
                {/* <img src="/images/target-2.svg" /> */}
                <TargetsText>{t('search.footer.target2')}</TargetsText>
              </TargetsItem>
              <TargetsItem>
                <InfoImage image="/images/video-1.svg" />
                {/* <img src="/images/video-1.svg" /> */}
                <TargetsText>{t('search.footer.target3')}</TargetsText>
              </TargetsItem>
            </TargetsSection>
          )}
        </InfoSection>
        <Inner>
          <Info>
            <LinksWrapper>
              {i18n.language === 'ru' ? (
                <>
                  <Link to={'/policy/personal'}>Пользовательское соглашение</Link>
                  <Link to={'/policy/privacy'}>Политика конфиденциальности</Link>
                </>
              ) : (
                <>
                  <Link to={'/policy/privacy'}>Public Privacy Policy</Link>
                  <Link to={'/policy/terms_of_use'}>Terms of use</Link>
                </>
              )}
            </LinksWrapper>
          </Info>
          <img src="/images/onboarding/h1svg.svg" width={192} alt="visaver" />
          <Copy>© {getYear} ViSaver</Copy>
        </Inner>
      </FooterContainer>
    </FooterStyled>
  );
};

export default Footer;
