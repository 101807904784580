import { VideoCard } from '../../../components/Card';
import FullScreenLoader from '../../../components/Shared/FullScreenLo/FullScreenLoader';
import RemoveVideo from '../../../components/Shared/Modals/RemoveVideo/RemoveVideo';

import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { videosAPI } from '@/api';
import { useHandlingError } from '@/hooks';
import { CardItem, CardList, InfoWrapper, InfoTitle } from '@/pages/LibraryPage';

const VideosWrapper = ({ isAdmin }: { isAdmin?: boolean }) => {
  const { t } = useTranslation('pages');
  const { catchError } = useHandlingError();
  const { data: videos, isLoading, isSuccess, error } = videosAPI.useGetMyVideosQuery({});

  useEffect(() => {
    catchError(error);
  }, [error]);

  return (
    <>
      <CardList>
        {isSuccess &&
          videos.map((movie) => (
            <CardItem key={movie.publicId}>
              <VideoCard
                movie={movie}
                to={
                  isAdmin ? `edit/${movie.publicId}/transcript` : `watch/${movie.publicId}?t=${movie.startsFrom ?? 0}`
                }
                menu
              />
            </CardItem>
          ))}
      </CardList>
      {isSuccess && videos.length === 0 && (
        <InfoWrapper>
          <InfoTitle>{t('library.no_v')}</InfoTitle>
        </InfoWrapper>
      )}
      {isLoading && <FullScreenLoader />}
      <RemoveVideo />
    </>
  );
};

export default VideosWrapper;
