import { Content, Header, HeaderTitle } from './LibraryPage.styled';

import LibraryTabsSwitcher from '../../components/TabsSwitcher/LibraryTabsSwitcher';

import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { videosAPI } from '@/api';

const LibraryPage = () => {
  const { t } = useTranslation('header');
  videosAPI.useGetMyVideosQuery({});
  return (
    <>
      <Header>
        <HeaderTitle>{t('menu.lib')} </HeaderTitle>
        {/* <SortingButton /> */}
      </Header>
      <Content>
        <LibraryTabsSwitcher />
        <Outlet />
      </Content>
    </>
  );
};

export default LibraryPage;
