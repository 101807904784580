import { PlaylistStatCardProps } from './PlaylistStatCard.props';
import {
  StatImageWrapper,
  StatsList,
  StatsItem,
  StatInner,
  InnerText,
  StatTitle,
  StatSubTitle,
  PlaylistStatCardStyled,
  StatisticsItemIcon,
  StatisticsItemText,
} from './PlaylistStatCard.styled';

import BookmarkIcon from '../../SvgIcons/BookmarkIcon';
import CommentsIcon from '../../SvgIcons/CommentsIcon';
import EyeIcon from '../../SvgIcons/EyeIcon';
import LikeIcon from '../../SvgIcons/LikeIcon';
import ShareIcon from '../../SvgIcons/ShareIcon';

import { useTranslation } from 'react-i18next';

export const PlaylistStatCard = ({ playlist }: PlaylistStatCardProps) => {
  const { t } = useTranslation('components');

  // const statisticsItems = useMemo(
  //   () => [
  //     { text: playlist.movie_count, icon: <>видео</> },
  //     { text: playlist.likes_count, icon: <LikeIcon /> },
  //     { text: playlist.views_count, icon: <EyeIcon /> },
  //     { text: playlist.comments_count, icon: <CommentsIcon /> },
  //     { text: playlist.reposts_count, icon: <ShareIcon /> },
  //     { text: playlist.bookmarked_count, icon: <BookmarkIcon /> },
  //   ],
  //   [playlist],
  // );

  return (
    <PlaylistStatCardStyled>
      <StatImageWrapper bgImage={playlist.background_image} />
      <StatInner>
        <InnerText>
          <StatTitle>{playlist.name.substring(0, 100)}</StatTitle>
          {/* <SubTitle>{playlist.description.substring(0, 100)}</SubTitle> */}
          <StatSubTitle>{playlist.description}</StatSubTitle>
          {/* <Description>
          </Description> */}
        </InnerText>
        <StatsList>
          <StatsItem>
            <StatisticsItemText>{playlist.movie_count}</StatisticsItemText>
            <StatisticsItemIcon>{t('video', { count: playlist.movie_count })}</StatisticsItemIcon>
          </StatsItem>
          <StatsItem>
            <StatisticsItemText>{playlist.views_count}</StatisticsItemText>
            <StatisticsItemIcon>
              <EyeIcon />
            </StatisticsItemIcon>
          </StatsItem>
          <StatsItem>
            <StatisticsItemText>{playlist.likes_count}</StatisticsItemText>
            <StatisticsItemIcon>
              <LikeIcon />
            </StatisticsItemIcon>
          </StatsItem>
          <StatsItem>
            <StatisticsItemText>{playlist.comments_count}</StatisticsItemText>
            <StatisticsItemIcon>
              <CommentsIcon />
            </StatisticsItemIcon>
          </StatsItem>
          <StatsItem>
            <StatisticsItemText>{playlist.reposts_count}</StatisticsItemText>
            <StatisticsItemIcon>
              <ShareIcon />
            </StatisticsItemIcon>
          </StatsItem>
          <StatsItem>
            <StatisticsItemText>{playlist.bookmarked_count}</StatisticsItemText>
            <StatisticsItemIcon>
              {' '}
              <BookmarkIcon />
            </StatisticsItemIcon>
          </StatsItem>
        </StatsList>
      </StatInner>
    </PlaylistStatCardStyled>
  );
};
