import { SearchVideoCardProps } from './SearchVideoCard.props';
import { VideoImageWrapper } from './SearchVideoCard.styled';

import PlayIcon from '../../SvgIcons/PlayIcon';
import { Title } from '../PlaylistCard';
import { PlayButton, VideoTime } from '../VideoCard';

import { memo } from 'react';

import { Link, useLocation } from 'react-router-dom';

const SearchVideoCard = ({
  video: { publicId, title, thumbnailUrl, startsFrom },
  portrait = false,
  withTime = false,
}: SearchVideoCardProps) => {
  const location = useLocation();

  return (
    <Link to={`${publicId}?t=${startsFrom}`} state={`${location.pathname}`}>
      <VideoImageWrapper background_image={thumbnailUrl} portrait={portrait}>
        <PlayButton>
          <PlayIcon />
        </PlayButton>
        {withTime && (
          <VideoTime>
            {new Date(startsFrom * 1000).toLocaleTimeString('ru-RU', {
              second: '2-digit',
              minute: '2-digit',
            })}
          </VideoTime>
        )}
      </VideoImageWrapper>
      <Title style={{ marginTop: '10px' }}>{title.substring(0, 100)}</Title>
    </Link>
  );
};

export default memo(SearchVideoCard);
