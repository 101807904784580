import { ButtonsWrapper, Form, Label, SourcesItem, SourcesLink, SourcesList } from './AddVideo.styled';

// import Select from 'react-select';

import VkIcon from '../../../SvgIcons/VkIcon';
import YouTubeIcon from '../../../SvgIcons/YouTubeIcon';
import Button from '../../Buttons/Button/Button';
import FullScreenLoader from '../../FullScreenLo/FullScreenLoader';
import Input from '../../Input/Input';
import { Modal, Inner, Title } from '../Modal';

import { ChangeEvent, FormEvent, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Alert, Collapse, IconButton, MenuItem, Select, type SelectChangeEvent } from '@mui/material';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';

import { videosAPI, playlistsAPI } from '@/api';
import { useAppSelector, useActions } from '@/hooks';
import { SecondaryText } from '@/styles';

export const SOURCES_DATA = [
  { link: 'https://www.youtube.com/', icon: <YouTubeIcon /> },
  // { link: 'https://www.tiktok.com/', icon: <TiktokIcon /> },
  { link: 'https://vk.com/video', icon: <VkIcon /> },
];

export const AddVideo = () => {
  const [videoLinkValue, setVideoLinkValue] = useState<string>('');

  const [selectedPlaylist, setSelectedPlaylist] = useState('');

  const { t } = useTranslation('modal');

  const { closeModal, showNotification } = useActions();

  const [, modal] = useAppSelector((state) => [state.user.isAuth, state.modal.active]);

  const { data: playlists } = playlistsAPI.useGetMyPlaylistsQuery({}, { skip: modal !== 'AddVideo' });
  const [addVideoToPlaylist] = playlistsAPI.useAddVideoToPlaylistMutation();
  const [createMovie, result] = videosAPI.useCreateVideoMutation();

  const [errorAlertIsOpen, setErrorAlertIsOpen] = useState<boolean>(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState<string>('');

  const closeHandler = () => {
    closeModal();
    setVideoLinkValue('');
    setSelectedPlaylist('');
    setErrorAlertIsOpen(false);
  };

  const addVideo = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (videoLinkValue.length && selectedPlaylist) {
      const newVideoId = await createMovie({ originLink: videoLinkValue });
      if ('data' in newVideoId) {
        // ReactGA.event({ category: 'video', action: 'add_video' });
        const payload = await addVideoToPlaylist({
          playlistId: selectedPlaylist,
          videos: [
            {
              videoPublicId: newVideoId.data.publicId,
              isAiSuggested: false,
            },
          ],
        });
        if ('data' in payload) {
          closeHandler();
          showNotification({
            text: t('add_video_pl', { ns: 'success' }),
            severity: 'success',
          });
        } else if ('status' in payload.error) {
          setErrorAlertIsOpen(true);
          setErrorAlertMessage(t('add_v_uncor_link', { ns: 'error' }));
        } else {
          setErrorAlertIsOpen(true);
          setErrorAlertMessage(t('error', { ns: 'error' }));
        }
      }
    } else if (videoLinkValue.length) {
      const newVideo = await createMovie({ originLink: videoLinkValue });
      if ('data' in newVideo) {
        closeHandler();

        showNotification({
          text: t('add_video', { ns: 'success' }),
          severity: 'success',
        });
        ReactGA.event({ category: 'video', action: 'add_video_to_library' });
      } else if ('status' in newVideo.error) {
        setErrorAlertIsOpen(true);
        setErrorAlertMessage(t('add_v_uncor_link', { ns: 'error' }));
      } else {
        setErrorAlertIsOpen(true);
        setErrorAlertMessage(t('error', { ns: 'error' }));
      }
    } else if (!videoLinkValue.length) {
      setErrorAlertIsOpen(true);
      setErrorAlertMessage(t('add_v_empty_link', { ns: 'error' }));
    }
  };

  const videoLinkHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setVideoLinkValue(event.target.value);
    setErrorAlertIsOpen(false);
  };

  // const onChangeSelect = (id?: string) => {
  //   if (id) {
  //     setSelectedPlaylist(id);
  //   }
  // };
  const onChangeSelect = (event: SelectChangeEvent<string>) => {
    setSelectedPlaylist(event.target.value);
  };

  return (
    <Modal modal={'AddVideo'} onClose={closeHandler} closeButton>
      <Title>{t('addVideo', { ns: 'header' })}</Title>
      <Inner modal={'AddVideo'}>
        <Form onSubmit={addVideo}>
          <Collapse in={errorAlertIsOpen}>
            <Alert
              severity="error"
              variant="filled"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setErrorAlertIsOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {errorAlertMessage}
            </Alert>
          </Collapse>
          <Label>
            <SecondaryText>{t('video_insert')}</SecondaryText>
            <Input placeholder={t('video_insert')} value={videoLinkValue} onChange={videoLinkHandler} />
          </Label>
          <Label>
            <SecondaryText>{t('video_choose')}</SecondaryText>
            <SourcesList>
              {SOURCES_DATA.map((item) => (
                <SourcesItem key={item.link}>
                  <SourcesLink href={item.link} target="_blank">
                    {item.icon}
                  </SourcesLink>
                </SourcesItem>
              ))}
            </SourcesList>
          </Label>
          <Label>
            <SecondaryText>{t('video_select_pl')}</SecondaryText>
            {/* <SelectWrapper>
              <Select
                id="selectMyPlaylists"
                className="basic-single"
                classNamePrefix="select"
                options={personalPlaylists ? personalPlaylists.results.map((item) => ({
                  value: item.id,
                  label: item.name
                })) : []}
                placeholder='Название'
                isDisabled={false}
                // isLoading={Boolean(selectIsLoading)}
                isSearchable={false}
                name="color"
                menuPlacement="auto"
                onChange={(event) => onChangeSelect(event?.value)}
              />
            </SelectWrapper> */}
            <Select
              style={{ color: 'white' }}
              // id="selectMyPlaylists"
              // className="basic-single"
              // classNamePrefix="select"
              value={selectedPlaylist}
              onChange={onChangeSelect}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              // placeholder="Название"
            >
              <MenuItem value={''}>
                <em>{t('video_no_select')}</em>
              </MenuItem>
              {playlists &&
                playlists.results.map((playlist, index) => (
                  <MenuItem key={index} value={playlist.publicId}>
                    {playlist.title}
                  </MenuItem>
                ))}
            </Select>
          </Label>

          <ButtonsWrapper>
            <Button theme="white" type="submit">
              {t('form.save', { ns: 'components' })}
            </Button>
            <Button onClick={closeHandler} theme="inline">
              {t('form.cancel', { ns: 'components' })}
            </Button>
          </ButtonsWrapper>
          {result.isLoading && <FullScreenLoader />}
        </Form>
      </Inner>
    </Modal>
  );
};
