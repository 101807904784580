import { ButtonsWrapper, HeaderContainer, HeaderStyled, Inner } from './Header.styled';

import { Button } from '../../../components/Shared/Buttons/Button';
import Logo from '../../../components/Shared/Logo/Logo';

import { memo } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useAppSelector } from '@/hooks';

const Header = () => {
  const { t } = useTranslation('pages');
  const isAuth = useAppSelector((state) => state.user.isAuth);

  return (
    <HeaderStyled>
      <HeaderContainer>
        <Inner>
          <Logo />
          {isAuth && (
            <ButtonsWrapper>
              <Link to={'/library'}>
                <Button theme="transparent">{t('search.header.pls')}</Button>
              </Link>
              <Link to="/library/videos">
                <Button theme="transparent">{t('search.header.saves')}</Button>
              </Link>
            </ButtonsWrapper>
          )}
        </Inner>
      </HeaderContainer>
    </HeaderStyled>
  );
};

export default memo(Header);
