import { Button } from '../Button';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const GoHomeButton = () => {
  const { t } = useTranslation('components');
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
    window.location.reload();
  };

  return (
    <Button size="big" onClick={handleClick}>
      {t('goHome', { ns: 'components' })}
    </Button>
  );
};

export default GoHomeButton;
