import {
  ButtonsWrapper,
  Form,
  Label,
  SourcesItem,
  SourcesLink,
  SourcesList,
  VideoImage,
  VideosList,
  VideosListItem,
  VideosWrapper,
  InputContent,
  VideoTitle,
  LabelBox,
  CheckBox,
} from './AddPlayListVideo.styled';

import Button from '../../Buttons/Button/Button';
import MyInput from '../../MyInput/MyInput';
import { SuggestVideoButton } from '../../SuggestVideoButton';
import { SOURCES_DATA } from '../AddVideo/AddVideo';
import { Inner, Modal, Title } from '../Modal';

import { FormEvent, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { videosAPI, playlistsAPI } from '@/api';
import { useActions, useAppSelector } from '@/hooks';
import { DescriptionText, SecondaryText } from '@/styles';
import { Playlist } from '@/types';

interface AddPlayListVideoModalProps {
  playlist: Playlist;
}

export const AddPlaylistVideo = ({ playlist }: AddPlayListVideoModalProps) => {
  // const [newVideoLinkValue, setNewVideoLinkValue] = useState<string>("");
  const [addedVideoItems, setAddedVideoItems] = useState<string[]>([]);
  const { t } = useTranslation('modal');
  // const videos = useTypedSelector((state) => state.videos.videos);
  const inputRef = useRef<HTMLInputElement>(null);
  const { closeModal, showNotification } = useActions();
  const modal = useAppSelector((state) => state.modal.active);

  const { data: videos } = videosAPI.useGetMyVideosQuery({}, { skip: modal !== 'AddPlayListVideo' });
  const [addToPlayList] = playlistsAPI.useAddVideoToPlaylistMutation();
  const [createMovie] = videosAPI.useCreateVideoMutation();

  const closeHandler = () => {
    closeModal();
    setAddedVideoItems([]);
  };

  const addToPlaylist = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // console.log(addedVideoItems)
    if (addedVideoItems.length) {
      const payload = await addToPlayList({
        playlistId: playlist.publicId,
        videos: [{ isAiSuggested: false, videoPublicId: addedVideoItems[0] }],
      });
      if ('data' in payload) {
        closeHandler();
        showNotification({
          text: t('add_videos_pl_n', { ns: 'success', name: playlist.title }),
          severity: 'success',
        });
      } else {
        console.log(payload);
      }
    } else if (inputRef.current && inputRef.current.value.length) {
      const newVideoId = await createMovie({ originLink: inputRef.current.value });

      if ('data' in newVideoId) {
        const payload = await addToPlayList({
          playlistId: playlist.publicId,
          videos: [{ isAiSuggested: false, videoPublicId: newVideoId.data.publicId }],
        });

        if ('data' in payload) {
          closeHandler();
          showNotification({
            text: t('add_video_pl', { ns: 'success' }),
            severity: 'success',
          });
        } else if ('status' in payload.error) {
          showNotification({
            text: t('add_v_uncor_link', { ns: 'error' }),
            severity: 'error',
          });
        } else {
          showNotification({
            text: t('add_v_uncor_link', { ns: 'error' }),
            severity: 'error',
          });
        }
      }
    } else if (!addedVideoItems.length && !inputRef.current?.value.length) {
      showNotification({
        text: t('add_v_not_sel', { ns: 'error' }),
        severity: 'error',
      });
    }
  };

  const addVideo = (id: string) => {
    const array = Object.assign([], addedVideoItems);
    if (array.includes(id)) {
      const index = array.indexOf(id);
      if (index > -1) {
        // only splice array when item is found
        array.splice(index, 1); // 2nd parameter means remove one item only
        setAddedVideoItems(array);
      }
    } else {
      array.push(id);
      setAddedVideoItems(array);
    }
  };

  return (
    <Modal modal={'AddPlayListVideo'} closeButton onClose={closeHandler}>
      <Title>{t('add_v_to_pl')}</Title>
      <Inner modal={'AddPlayListVideo'}>
        <Form onSubmit={addToPlaylist}>
          <Label>
            <SecondaryText>{t('add_v_to_pl_insert')}</SecondaryText>
            <VideosWrapper>
              {videos?.length === 0 && <DescriptionText>{t('add_v_to_pl_no_v')}</DescriptionText>}
              <VideosList>
                {videos &&
                  videos
                    .filter((video) => !playlist.videos.map((movie) => movie.publicId).includes(video.publicId))
                    .map((video, index) => (
                      <VideosListItem
                        key={index}
                        onClick={() => {
                          addVideo(video.publicId);
                        }}
                      >
                        <LabelBox>
                          <InputContent>
                            {video.source === 'YOUTUBE' ? (
                              <VideoImage src={`https://i.ytimg.com/vi/${video.videoId}/maxresdefault.jpg`} />
                            ) : (
                              <VideoImage src={video.thumbnailUrl} />
                            )}
                            <VideoTitle>{video.title}</VideoTitle>
                          </InputContent>
                        </LabelBox>
                        <CheckBox defaultChecked={addedVideoItems.includes(video.publicId)} />
                      </VideosListItem>
                    ))}
              </VideosList>
            </VideosWrapper>
          </Label>
          <MyInput label={t('video_insert_add_v')} placeholder={t('video_insert')} ref={inputRef} />
          {/* <SecondaryText>
                Вставьте ссылку на понравившееся видео{" "}
              </SecondaryText>
              <Input
                value={newVideoLinkValue}
                onChange={(event) =>
                  setNewVideoLinkValue(event.target.value)
                }
                placeholder="Вставьте ссылку на понравившееся видео"
              /> */}
          <Label>
            <SecondaryText>{t('video_choose')}</SecondaryText>
            <SourcesList>
              {SOURCES_DATA.map((item) => (
                <SourcesItem key={item.link}>
                  <SourcesLink href={item.link} target="_blank">
                    {item.icon}
                  </SourcesLink>
                </SourcesItem>
              ))}
            </SourcesList>
          </Label>
          <SuggestVideoButton
            previouslySuggestedVideos={playlist.listAiSuggestedVideoPks}
            publicId={playlist.publicId}
          />
          <ButtonsWrapper>
            <Button theme="white" type="submit">
              {t('form.save', { ns: 'components' })}
            </Button>
            <Button onClick={closeHandler} theme="inline">
              {t('form.cancel', { ns: 'components' })}
            </Button>
          </ButtonsWrapper>
        </Form>
      </Inner>
    </Modal>
  );
};
