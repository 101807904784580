import { useLatest } from './useLatest';

import { ChangeEvent, useEffect, useState } from 'react';

import { useLocation, useSearchParams } from 'react-router-dom';

export const usePagination = () => {
  const location = useLocation();
  const [params, setParams] = useSearchParams();

  const [count, setCount] = useState<number | undefined>();

  const limitParamFunc = useLatest(() => {
    const limit = params.get('limit');
    return limit ? parseInt(limit) : 20;
  });

  const pageParamFunc = useLatest(() => {
    const page = params.get('page');
    return page ? parseInt(page) : 1;
  });

  const [pagination, setPagination] = useState({
    pages: 0,
    page: pageParamFunc.current(),
  });

  const [limit, setLimit] = useState(limitParamFunc.current());

  useEffect(() => {
    if (pagination.page !== pageParamFunc.current()) {
      setPagination((prev) => ({ ...prev, page: pageParamFunc.current() }));
    }
  }, [location]);

  useEffect(() => {
    if (count && count > limit - 1) {
      const pages = Math.ceil(count / limit);
      setPagination((prev) => ({ ...prev, pages }));
    } else if (count && count <= limit - 1) {
      setPagination((prev) => ({ ...prev, pages: 1 }));
    }
  }, [limit, count]);

  const handleChangePage = (e: ChangeEvent<unknown>, page: number) => {
    e.target;
    setPagination((prev) => ({ ...prev, page }));

    setParams((params) => {
      params.set('page', page.toString());
      return params;
    });
    window.scrollTo(0, 0);
  };

  const handleChangeLimit = (limit: number) => {
    setLimit(limit);
    setPagination((prev) => ({ ...prev, page: 1 }));

    setParams((params) => {
      params.set('limit', limit.toString());
      return params;
    });
  };

  return { pagination, setPagination, setCount, params, setParams, handleChangePage, limit, handleChangeLimit };
};
