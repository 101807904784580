import { Button } from '../../../../components/Shared/Buttons/Button';

import styled from 'styled-components';

import { theme } from '@/styles';

type TLine = {
  isActive?: boolean;
};

export const SliderSection = styled.section`
  background-image: url('../../../../../public/images/onboardingNew/vector-logo-bg-Mobile.svg');
  background-repeat: no-repeat;
  background-position: left;
  width: 100vw;
  height: 100vh;

  @media (min-width: 992px) {
    background-image: url('../../../../../public/images/onboardingNew/logo-bg-desktop.svg');
  }
`;

export const SliderBg = styled.div`
  background-image: url('../../../../../public/images/onboardingNew/blur-ellipses-mobile.svg');
  background-repeat: round;
  background-size: 92%;
  height: 100%;

  @media (min-width: 1400px) {
    background-image: url('../../../../../public/images/onboardingNew/blur-ellipses-bg2.png');
  }
`;

export const NavBar = styled.nav`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: end;

  @media (min-width: 576px) {
    padding-top: 20px;
  }

  @media (min-width: 1200px) {
    width: 62vw;
  }
`;

export const ButtonSkip = styled(Button)`
  & p {
    color: ${theme.colors.white.white_70};
    &:active {
      color: ${theme.colors.white.white_100};
    }
  }
`;

export const SlideContentWrapper = styled.div`
  height: 78vh;
  padding-left: 15px;
  padding-right: 15px;
  overflow: hidden;

  @media (min-width: 992px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const ControlsWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 576px) {
    padding-left: 25px;
    padding-right: 25px;
  }

  @media (min-width: 1200px) {
    width: 65vw;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const ProgressBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Line = styled.hr<TLine>`
  display: block;
  border: 3px solid ${(props) => (props.isActive ? '#E4E4FF' : '#2B1759')};
  transition: border-color 0.5s ease;
  margin-right: 20px;
  margin-bottom: 5.56vh;
  border-radius: 10px;
  cursor: pointer;

  @media (min-width: 576px) {
    width: 15vw;
    border-radius: 2px;
  }

  @media (min-width: 992px) {
    border: 2px solid ${(props) => (props.isActive ? '#E4E4FF' : '#2B1759')};
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 30px;

  @media (max-width: 572px) {
    margin: 0 auto;
    width: max-content;
    //width: 95vw;
    //margin-left: auto;
    //margin-right: auto;
    gap: 20px;
    & > button {
      width: 45vw;
    }
  }
`;

export const ImgMobile = styled.img`
  padding: 20px;
`;
