import { Wrapper } from './ShareButtonStyle';

import ShareIconBig from '../../../SvgIcons/ShareIconBig';

interface ShareButtonProps {
  onClick: () => void;
}

const ShareButton = ({ onClick }: ShareButtonProps) => {
  return (
    <Wrapper onClick={onClick}>
      <ShareIconBig />
    </Wrapper>
  );
};

export default ShareButton;
