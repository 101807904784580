import { ButtonText, SecondaryText, TitleH3, TitleH4 } from '../../styles/components';
import { theme } from '../../styles/theme';

import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 60px 0;
`;
export const Inner = styled.div`
  display: flex;
  gap: 20px;
`;
export const Content = styled.div`
  max-width: 900px;
  width: 100%;
`;
export const UserBlock = styled.div`
  width: 289px;
  height: 304px;
  background-color: ${theme.colors.blue.blue_dark};
  border-radius: 20px;
  margin-top: 60px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const UserImage = styled.img`
  width: 180px;
  height: 180px;
  border-radius: 20px;
  background-color: ${theme.colors.blue.blue_5};
  object-fit: cover;
  object-position: center;
  margin: -60px 0;
  display: block;
`;

export const UserName = styled(TitleH4)`
  margin-top: 80px;
`;
export const UserEmail = styled(SecondaryText)`
  color: ${theme.colors.white.white_40};
  margin-bottom: 24px;
`;

export const ContentItem = styled.div``;
export const ContentItemHeader = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ContentTitle = styled(TitleH3)``;
export const WatchAll = styled(ButtonText)``;
export const ContentItemList = styled.ul`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
`;
export const ContentItemListItem = styled.li``;
