import { TitleH4 } from '../../../../styles/components';

import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  left: 0;
`;
export const Inner = styled.div`
  position: relative;
  width: 800px;
  max-height: 500px;
  height: 100vh;
  flex-direction: column;
`;

export const VideoWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 800px;
`;

export const ExitButtonWrapper = styled.button`
  position: absolute;
  top: 10px;
  right: 20px;
`;

export const Title = styled(TitleH4)`
  margin-bottom: 30px;
  /* margin-top: 30px; */
  /* margin-top: a; */
`;
