import { theme } from '../../../styles';

import { Snackbar } from '@mui/material';
import styled from 'styled-components';

export const NotificationStyled = styled(Snackbar)`
  .MuiAlert-root {
    padding: 12px 32px;
    border-radius: 20px;

    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    min-width: 35%;

    /* width: 500px; */
    @media screen and (max-width: 1440px) {
      padding: 12px 32px;
      font-size: 18px;
      line-height: 26px;
    }
    @media screen and (max-width: 1024px) {
      padding: 8px 30px;
      border-radius: 15px;
      font-size: 17px;
      line-height: 24px;
    }
    @media screen and (max-width: 768px) {
      padding: 6px 28px;
      font-size: 15px;
      line-height: 21px;
    }
    @media screen and (max-width: 480px) {
      padding: 4px 16px;
      border-radius: 12px;
      font-size: 13px;
      line-height: 18px;
    }
  }

  .MuiAlert-action {
    display: none;
  }

  .MuiAlert-icon {
    display: none;
  }

  .MuiAlert-filledError {
    background-color: ${theme.colors.red.red_light};
    color: ${theme.colors.blue.blue_dark};
  }

  .MuiAlert-filledSuccess {
    background-color: ${theme.colors.blue.blue_5};
    color: ${theme.colors.white.white_100};
  }
`;
