import { SVGProps } from './svg.props';

import { theme } from '../../styles/theme';

const ArrowSmall = (props: SVGProps) => (
  <svg {...props} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M9 1L5 5L1 1" stroke={theme.colors.white.white_100} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default ArrowSmall;
