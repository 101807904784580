import { Button } from '../../components/Shared/Buttons/Button';
import MyInput from '../../components/Shared/MyInput/MyInput';

import styled from 'styled-components';

export const SearchStyled = styled.main``;
export const SearchSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  height: 100%;
  padding-top: 3.7%;
  background: radial-gradient(146.77% 99.87% at 96.01% 40.43%, #150675 0%, #0b012c 100%), #0b002c,
    linear-gradient(180deg, rgba(155, 36, 201, 0.5) 0%, rgba(0, 174, 148, 0.5));

  @media screen and (max-width: 1000px) {
    padding-top: 0;
  }
`;

export const Title = styled.h1`
  color: #dad3ff;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  text-transform: uppercase;

  @media screen and (max-width: 1000px) {
    width: 343px;
  }
`;
export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
`;

export const SearchButton = styled(Button)`
  padding: 12px 48px;
`;
export const SearchInput = styled(MyInput)`
  input {
    border-radius: 10px;
    border: 2px solid #e4e4ff;
    display: flex;
    width: 800px;
    padding: 12px 16px 14px 60px;
    align-items: center;
    gap: 10px;
    @media screen and (max-width: 1000px) {
      width: 80vw;
    }
    @media screen and (max-width: 480px) {
      padding-left: 40px;
    }
  }

  svg {
    position: absolute;
    top: 25%;
    left: 2%;
    path {
      stroke: #e4e4ff;
    }
  }
`;
