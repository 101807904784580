import { theme } from '../../../../styles/theme';

import styled, { css } from 'styled-components';

export const Wrapper = styled.button<{ isLoading: boolean }>`
  min-width: 54px;
  height: 52px;
  ${(props) => {
    if (props.isLoading) {
      return css`
        color: ${theme.colors.white.white_100};
      `;
    }
  }};
  background: ${theme.colors.blue.blue_20};
  color: ${theme.colors.white.white_100};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 10px;
  padding: 5px 10px;

  transition: all 0.3s;

  :before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
    opacity: 1;
  }

  :hover {
    scale: 1.02;
  }

  :after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${theme.colors.blue.blue_20};
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
`;
