import { Wrapper } from './SearchButton.styled';

import SearchIcon from '../../../SvgIcons/SearchIcon';

interface SearchButtonProps {
  onClick: () => void;
}

const SearchButton = ({ onClick }: SearchButtonProps) => {
  return (
    <Wrapper onClick={onClick}>
      <SearchIcon />
    </Wrapper>
  );
};

export default SearchButton;
