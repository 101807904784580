import { FooterText } from '../../styles';

import styled from 'styled-components';

export const FooterStyled = styled.footer`
  /* margin-top: 300px; */
`;
export const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  gap: 10px;
`;
export const Copy = styled(FooterText)``;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;

  & > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 10px;
  }
`;
export const LinksWrapper = styled.div`
  & a {
    color: white;
    opacity: 0.8;
    text-align: end;
  }

  @media (max-width: 480px) {
    font-size: 11px;
  }
`;
