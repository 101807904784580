import { TimecodesFormWrapper } from './TimecodesForm.styled';

import { Buttons, TimecodesButtonWrapper, TimecodesWrapper } from '../../EditTimecodesPage.styled';
import { TitleTime } from '../TitleAndTime';

import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { adminAPI } from '@/api';
import { Button } from '@/components';
import PlusBigIconBlack from '@/components/SvgIcons/PlusBigIconBlack';
import { useActions } from '@/hooks';
import { Timecode, TransformedTimecodesResponse } from '@/types';

export const TimecodesForm = ({ timecodes, publicId }: TransformedTimecodesResponse) => {
  const id = location.pathname.split('/')[4];
  // const playlistId = location.pathname.split('/')[2];
  // const timecodes = useAppSelector(state => state.timecodesAdmin.timecodesAdmin)
  const [deleteTimecodes] = adminAPI.useDeleteTimecodesAdminMutation();
  const [updateTimecodes] = adminAPI.usePutTimecodesAdminMutation();
  const { t } = useTranslation();

  const { showNotification } = useActions();

  const methods = useForm<{ timecodes: Timecode[] }>({ defaultValues: { timecodes } });

  const onFormSubmit = async ({ timecodes }: { timecodes: Timecode[] }) => {
    try {
      const resultData = {
        data: {
          timecodes: timecodes,
        },
      };

      const result = await updateTimecodes({ videoPk: id, publicId: publicId, body: resultData }).unwrap();
      console.log(result);

      showNotification({
        text: t('save_timecodes', { ns: 'success' }),
        severity: 'success',
      });
    } catch (e) {
      showNotification({
        text: t('save_timecodes', { ns: 'error' }),
        severity: 'error',
      });
    }
  };

  const { fields, append, remove } = useFieldArray<{ timecodes: Timecode[] }, 'timecodes', 'key'>({
    control: methods.control,
    name: 'timecodes',
    keyName: 'key',
  });

  const handleDeleteTimecodes = async () => {
    const result = await deleteTimecodes({ videoPk: id, publicId: publicId });
    remove();

    if ('error' in result) {
      showNotification({
        text: t('del_timecodes', { ns: 'error' }),
        severity: 'error',
      });
    } else {
      showNotification({
        text: t('del_timecodes', { ns: 'success' }),
        severity: 'success',
      });
    }
  };

  const handleAddNewTimecodes = () => {
    append({ start: 0, text: '', title: '' });
  };

  return (
    <FormProvider {...methods}>
      {timecodes && (
        <TimecodesFormWrapper onSubmit={methods.handleSubmit(onFormSubmit)}>
          <TimecodesButtonWrapper>
            <TimecodesWrapper>
              {fields.map((timecode, index) => (
                <TitleTime key={timecode.key} index={index} start={timecode.start} />
              ))}
            </TimecodesWrapper>
            <Button theme="white" style={{ padding: '13px' }} onClick={handleAddNewTimecodes}>
              <PlusBigIconBlack />
            </Button>
          </TimecodesButtonWrapper>
          <Buttons>
            <Button theme="white" type="submit">
              Сохранить
            </Button>
            <Button onClick={handleDeleteTimecodes}>Удалить</Button>
          </Buttons>
        </TimecodesFormWrapper>
      )}
    </FormProvider>
  );
};
