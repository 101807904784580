import { InnerCardProps } from './SearchVideoCard.props';

import { theme } from '../../../styles';

import styled from 'styled-components';

export const VideoCardWrapper = styled.div``;

export const InnerCard = styled.div``;

export const PlayButton = styled.button`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const VideoTime = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #e4e4ff;
  padding: 9px 12px;
  display: flex;
  background: rgba(23, 8, 123, 0.3);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  width: max-content;
  align-items: center;
  user-select: none;
  margin-left: auto;
  margin-bottom: 10px;
`;

export const VideoImageWrapper = styled.div<InnerCardProps>`
  background-color: ${theme.colors.blue.blue_20};
  background-image: url(${(props) => `${props.background_image}`});
  background-size: cover;
  background-position: center center;
  border-radius: 20px;
  min-width: ${(props) => (props.portrait ? '210px' : '289px')};
  max-width: ${(props) => (props.portrait ? '210px' : '320px')};
  width: 100%;
  height: ${(props) => (props.portrait ? '330px' : '200px')};
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 16px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
`;
