import { BackLink, BackLinkText } from './GoBackButton.styled';

import { ButtonHTMLAttributes, memo } from 'react';

import { useTranslation } from 'react-i18next';

import { GoHomeButtonStyled } from '@/components/Shared/Buttons/GoHomeButton/GoHomeButton.styled';
import ArrowLeft from '@/components/SvgIcons/ArrowLeft';

export type GoBackButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const GoBackButton = (props: GoBackButtonProps) => {
  const { t } = useTranslation('components');
  return (
    <GoHomeButtonStyled theme="transparent" {...props}>
      <BackLink>
        <ArrowLeft />
        <BackLinkText>{t('goBack')}</BackLinkText>
      </BackLink>
    </GoHomeButtonStyled>
  );
};

export default memo(GoBackButton);
