import styled, { css } from 'styled-components';

export const SocialsStyled = styled.div`
  /* position: absolute;
	bottom: 0; */
  /* max-width: 1440px; */

  width: 99vw;
  /* background: white; */
  .carousel {
  }
`;
export const Social = styled.div<{ icon: string }>`
  width: 50px;
  height: 50px;
  ${(props) => css`
    background: url(${props.icon}) center no-repeat;
  `}

  @media screen and (max-width: 1000px) {
    display: none;
  }
  /* background: red; */
  /* border: 2px solid black; */
`;
