import { LogoStyled } from './Logo.styled';

import { useActions } from '../../../hooks/useActions';
import { LogoIcon } from '../../SvgIcons/LogoIcon';

import { Link } from 'react-router-dom';

const Logo = () => {
  const { resetSearch } = useActions();
  return (
    <LogoStyled>
      <Link to="/" onClick={() => resetSearch()} replace>
        <LogoIcon />
      </Link>
    </LogoStyled>
  );
};

export default Logo;
