export const LogoIcon = () => (
  <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M20.5302 45.4332C18.943 45.4332 17.3629 44.9955 15.9568 44.1283C13.3917 42.5456 11.8608 39.8096 11.8608 36.8105V19.4972C11.8608 17.4582 13.5248 15.7289 15.5779 15.7289C17.631 15.7289 19.295 17.4582 19.295 19.4972V36.8099C19.295 37.4332 19.6502 37.7353 19.8806 37.8492C20.2909 38.0521 20.7536 38.0592 21.192 37.7059C24.6173 34.9449 32.9878 28.2102 36.945 25.0243C37.4954 24.5814 37.8493 24.2697 37.8493 23.7846C37.8493 23.3366 37.7142 23.0153 37.0768 22.6793C30.4835 19.2067 16.9117 12.0528 9.99904 8.41692C9.49024 8.1494 8.9648 8.32156 8.75104 8.4534C8.53216 8.58844 8.16544 8.90396 8.16544 9.4998V11.9977C8.16544 14.0368 6.58144 15.7328 4.52896 15.7328C2.47648 15.7328 0.731201 14.0368 0.731201 11.9977V9.4998C0.731201 6.50076 2.26272 3.76476 4.8272 2.18204C7.39168 0.598682 10.5834 0.361882 13.247 1.775C20.1238 5.42364 33.6752 12.5398 40.5181 16.1347C43.4358 17.6675 45.297 20.55 45.297 23.8326C45.297 26.9782 43.313 29.3692 40.881 31.3385C36.7856 34.655 29.8659 40.2595 25.7232 43.5977C24.136 44.8771 21.928 45.4332 20.5302 45.4332Z"
      fill="url(#paint0_linear_2558_1282)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2558_1282"
        x1="5.51002"
        y1="34.0166"
        x2="32.2549"
        y2="7.27167"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#970ECC" />
        <stop offset="0.5" stopColor="#9311C7" />
        <stop offset="1" stopColor="#14FC95" />
      </linearGradient>
    </defs>
  </svg>
);
