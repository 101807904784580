import { Text, TitleH3, CardsList } from '../../styles/components';

import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 30px;
`;

export const HeaderTitle = styled(TitleH3)``;

export const CardList = styled(CardsList)``;

export const CardItem = styled.li`
  overflow-wrap: anywhere;
  cursor: pointer;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const InfoTitle = styled(Text)`
  max-width: 560px;
  text-align: center;
  padding: 0 10px;
`;
