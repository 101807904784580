import FullScreenLoader from '../../../components/Shared/FullScreenLo/FullScreenLoader';

import { Text } from '@/pages/VideoPage/components/Timecodes.styled';

type TimecodeLoaderPropsType = {
  t: any;
};

export const TimecodeLoader = ({ t }: TimecodeLoaderPropsType) => {
  return (
    <>
      <Text>{t('load_timecodes', { ns: 'loading' })}</Text>
      <div style={{ marginBottom: '100px' }}>
        <FullScreenLoader />
      </div>
    </>
  );
};
