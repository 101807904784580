import styled from 'styled-components';

export const VideoCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  animation: skale-in 0.4s;

  @keyframes skale-in {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

export const InnerCard = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
`;

export const PlayButton = styled.button`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const VideoTime = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #e4e4ff;
  padding: 9px 12px;
  display: flex;
  background: rgba(23, 8, 123, 0.3);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  width: max-content;
  align-items: center;
  user-select: none;
  margin-left: auto;
`;

export const VideoMenu = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;
  margin-left: auto;
  height: 100%;
`;

export const EditButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
