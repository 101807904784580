// import BookmarkIcon from '../../../components/SvgIcons/BookmarkIcon';
// import CommentsIcon from '../../../components/SvgIcons/CommentsIcon';
// import EyeIcon from '../../../components/SvgIcons/EyeIcon';
// import LikeIcon from '../../../components/SvgIcons/LikeIcon';
// import ShareIcon from '../../../components/SvgIcons/ShareIcon';
import { PersonalPlaylist, UserPlaylist } from '../../../types';

import { t } from 'i18next';

export function getStatisticsItems<T extends PersonalPlaylist | UserPlaylist>(playlist: T) {
  return [
    { text: playlist.videos.length, icon: <>{t('video', { count: playlist.videos.length, ns: 'components' })}</> },
    // { text: "0 ч 21 мин", icon: <></> },
    // { text: playlist.likes_count, icon: <LikeIcon /> },
    // { text: playlist.views_count, icon: <EyeIcon /> },
    // { text: playlist.comments_count, icon: <CommentsIcon /> },
    // { text: playlist.reposts_count, icon: <ShareIcon /> },
    // { text: playlist.bookmarked_count, icon: <BookmarkIcon /> },
  ];
}
