import styled from 'styled-components';

export const LogoStyled = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;

  /* outline: none; */

  @media screen and (max-width: 480px) {
    width: 48px;
    height: 48px;

    & svg {
      width: 33px;
      height: 33px;
    }
  }
`;
