import { PlaylistCardProps } from './PlaylistCard.props';
import {
  ImageWrapper,
  // StatisticsList,
  // StatisticsItem,
  Inner,
  Title,
  SubTitle,
  VideoQuantity,
  UserInfo,
  UserName,
  PlaylistCardStyled,
} from './PlaylistCard.styled';

import { useAppSelector } from '../../../hooks/useStore';
import Avatar from '../../Shared/Avatar';
// import CommentsIcon from '../../SvgIcons/CommentsIcon';
// import EyeIcon from '../../SvgIcons/EyeIcon';
// import LikeIcon from '../../SvgIcons/LikeIcon';

import { memo } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

// import { LockIcon } from '@/components/SvgIcons';

const PlaylistCard = ({ playlist, className }: PlaylistCardProps) => {
  const { title, description, category, publicId, owner } = playlist;
  // const navigate = useNavigate();
  const { t } = useTranslation('components');
  const id = useAppSelector((state) => state.user.user_id);

  const location = useLocation();

  const navigateLinkHandler = () => {
    if (owner.publicId === id) {
      return `/playlist/${publicId}?u=${id}`;
    } else {
      return `/playlistview/${publicId}`;
    }
  };
  // const navigateUserHandler = useCallback(() => {
  //   navigate(`/user/${playlist.owner.id}`);
  // }, [playlist, navigate]);

  return (
    <PlaylistCardStyled className={className}>
      <Link to={navigateLinkHandler()} state={{ location: location.pathname }}>
        <ImageWrapper bgImage={category.image ?? '/images/playlist_mock.png'}>
          {/* <Link to={"/user"}> */}
          {/* <UserInfo onClick={navigateUserHandler}> */}
          <UserInfo>
            <Avatar name={playlist.owner.username} size="xs" url={playlist.owner.avatar} auth={true} />
            <UserName>{playlist.owner.username.slice(0, 10)}</UserName>
          </UserInfo>
          {/* </Link> */}
          {playlist.videos.length === 0 ? null : (
            <VideoQuantity>{`${playlist.videos.length} ${t('pl_video_count', { ns: 'components' })}`}</VideoQuantity>
          )}
        </ImageWrapper>
        <Inner>
          {/* <StatisticsList>
            <StatisticsItem>
              {playlist.likes_count} <LikeIcon />
            </StatisticsItem>
            <StatisticsItem>
              {playlist.comments_count}
              <CommentsIcon />
            </StatisticsItem>
            <StatisticsItem>
              {playlist.views_count} <EyeIcon />
            </StatisticsItem>
          </StatisticsList> */}
          <Title>{title.substring(0, 100)}</Title>
          <SubTitle>{description.substring(0, 100)}</SubTitle>
        </Inner>
      </Link>
    </PlaylistCardStyled>
  );
};

export default memo(PlaylistCard);
