import { SuggestVideoCardImage, SuggestVideoCardStyled, SuggestVideoCardTitle } from './SuggestVideo.styled';

import { PlusButton } from '../../Buttons';

import { SetStateAction } from 'react';

import { useActions } from '@/hooks';

interface SuggestVideoCardProps {
  title: string;
  thumbnailUrl: string;
  publicId: string;
  isPick?: boolean;
  setVideoIds: (value: SetStateAction<string[]>) => void;
}
export const SuggestVideoCard = ({
  publicId,
  thumbnailUrl,
  title,
  isPick = false,
  setVideoIds,
}: SuggestVideoCardProps) => {
  const { openModal } = useActions();
  const onAddClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setVideoIds((prev) => [...prev, publicId]);
  };
  // const onDeleteClick = (e: React.MouseEvent) => {
  //   e.stopPropagation();
  //   setVideoIds((prev) => prev.filter((id) => id !== publicId));
  // };

  const showVideo = () => {
    openModal({ active: 'Video', data: { id: publicId } });
  };
  return (
    <SuggestVideoCardStyled onClick={showVideo}>
      <SuggestVideoCardImage thumbnailUrl={thumbnailUrl} />
      <SuggestVideoCardTitle>{title}</SuggestVideoCardTitle>
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        {/* {isPick ? <TrashButton onClick={onDeleteClick} /> : <PlusButton onClick={onAddClick} />} */}
        {!isPick && <PlusButton onClick={onAddClick} />}
      </div>
    </SuggestVideoCardStyled>
  );
};
