import { Social, SocialsStyled } from './Socials.styled.';

import { Component } from 'react';

import Slider from 'react-slick';

export default class Socials extends Component {
  render() {
    const settings = {
      className: 'carousel',
      dots: false,
      arrows: false,
      accessibility: false,
      infinite: true,
      slidesToShow: 11,
      slidesToScroll: 1,
      autoplay: true,
      draggable: false,
      pauseOnHover: false,
      swipe: false,
      touchMove: false,
      speed: 5000,
      autoplaySpeed: 0,
      cssEase: 'linear',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 9,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 8,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <SocialsStyled>
        <Slider {...settings}>
          <div>
            <Social icon="/images/socialIn.svg" />
          </div>
          <div>
            <Social icon="/images/socialVK.svg" />
          </div>
          <div>
            <Social icon="/images/socialT.svg" />
          </div>
          <div>
            <Social icon="/images/socialF.svg" />
          </div>
          <div>
            <Social icon="/images/socialTwit.svg" />
          </div>
          <div>
            <Social icon="/images/socialInst.svg" />
          </div>
          <div>
            <Social icon="/images/socialP.svg" />
          </div>
          <div>
            <Social icon="/images/socialY.svg" />
          </div>
          <div>
            <Social icon="/images/socialIn.svg" />
          </div>
          <div>
            <Social icon="/images/socialVK.svg" />
          </div>
          <div>
            <Social icon="/images/socialT.svg" />
          </div>
          <div>
            <Social icon="/images/socialF.svg" />
          </div>
          <div>
            <Social icon="/images/socialTwit.svg" />
          </div>
          <div>
            <Social icon="/images/socialInst.svg" />
          </div>
          <div>
            <Social icon="/images/socialP.svg" />
          </div>
          <div>
            <Social icon="/images/socialY.svg" />
          </div>
          <div>
            <Social icon="/images/socialIn.svg" />
          </div>
          <div>
            <Social icon="/images/socialVK.svg" />
          </div>
          <div>
            <Social icon="/images/socialT.svg" />
          </div>
          <div>
            <Social icon="/images/socialF.svg" />
          </div>
          <div>
            <Social icon="/images/socialTwit.svg" />
          </div>
          <div>
            <Social icon="/images/socialInst.svg" />
          </div>
          <div>
            <Social icon="/images/socialP.svg" />
          </div>
          <div>
            <Social icon="/images/socialY.svg" />
          </div>
          <div>
            <Social icon="/images/socialIn.svg" />
          </div>
          <div>
            <Social icon="/images/socialVK.svg" />
          </div>
          <div>
            <Social icon="/images/socialT.svg" />
          </div>
          <div>
            <Social icon="/images/socialF.svg" />
          </div>
          <div>
            <Social icon="/images/socialTwit.svg" />
          </div>
          <div>
            <Social icon="/images/socialInst.svg" />
          </div>
          <div>
            <Social icon="/images/socialP.svg" />
          </div>
          <div>
            <Social icon="/images/socialY.svg" />
          </div>
        </Slider>
      </SocialsStyled>
    );
  }
}
