import { Title, Wrapper, Description, Content } from './ErrorPage.styled';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components';

const ErrorPage = () => {
  const { t } = useTranslation('pages');
  const navigate = useNavigate();

  const goHome = () => {
    () => navigate('/');
    window.location.reload();
  };

  return (
    <Wrapper>
      <Content>
        <Title>{t('404.ops')}</Title>
        <Description>{t('404.info')}</Description>
        <Button size="big" onClick={goHome}>
          {t('goHome', { ns: 'components' })}
        </Button>
      </Content>
    </Wrapper>
  );
};

export default ErrorPage;
