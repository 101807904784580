export function secondsToTime(s: number) {
  const hours = Math.floor(s / 3600);
  const minutes = Math.floor((s % 3600) / 60);
  const remainingSeconds = s % 60;

  const timeStr = (time: number) => time.toString().padStart(2, '0');

  if (hours > 0) {
    return hours + ':' + timeStr(minutes) + ':' + timeStr(remainingSeconds);
  } else if (minutes > 0) {
    return timeStr(minutes) + ':' + timeStr(remainingSeconds);
  } else {
    return '00:' + timeStr(remainingSeconds);
  }
}
