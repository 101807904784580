import styled from 'styled-components';

export const Wrapper = styled.button`
  width: 54px;
  height: 52px;
  background: transparent;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
