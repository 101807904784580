import { Wrapper } from './TrashButtonStyled';

import TrashIcon from '../../../SvgIcons/TrashIcon';

import { MouseEvent } from 'react';

interface TrashButtonProps {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  bg?: 'red' | 'black' | 'white' | 'none';
}

const TrashButton = ({ bg = 'black', onClick }: TrashButtonProps) => {
  return (
    <Wrapper onClick={onClick} type="button" variant="withIcon" theme={bg}>
      <TrashIcon />
    </Wrapper>
  );
};

export default TrashButton;
