import { Copy, Inner, FooterStyled, Info, LinksWrapper } from './Footer.styled';

import Container from '../Shared/Container/Container';
import Logo from '../Shared/Logo/Logo';

import { memo, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Footer = () => {
  const getYear = useMemo(() => new Date().getFullYear(), []);
  const { i18n } = useTranslation();

  return (
    <FooterStyled>
      <Container>
        <Inner>
          <Logo />
          <Info>
            <Copy>© {getYear} ViSaver</Copy>
            <LinksWrapper>
              {i18n.language === 'ru' ? (
                <>
                  <Link to={'/policy/personal'}>Пользовательское соглашение</Link>
                  <Link to={'/policy/privacy'}>Политика конфиденциальности</Link>
                </>
              ) : (
                <>
                  <Link to={'/policy/privacy'}>Public Privacy Policy</Link>
                  <Link to={'/policy/terms_of_use'}>Terms of use</Link>
                </>
              )}
            </LinksWrapper>
          </Info>
        </Inner>
      </Container>
    </FooterStyled>
  );
};

export default memo(Footer);
