const EditIcon = ({ width = 22, height = 22, ...props }: SVGProps) => (
  <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="https://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.8693 4.48088C11.1314 6.09639 11.9428 7.58229 13.1762 8.70534C14.4095 9.82838 15.995 10.525 17.6836 10.6858M12.5822 2.73386L2.31695 13.2039C1.92934 13.6015 1.55424 14.3846 1.47922 14.9268L1.01659 18.8305C0.854047 20.2402 1.90433 21.204 3.35473 20.9631L7.38083 20.3004C7.94348 20.204 8.73119 19.8064 9.1188 19.3968L19.3841 8.92673C21.1596 7.11947 21.9598 5.0592 19.1965 2.54109C16.4458 0.0470747 14.3577 0.926606 12.5822 2.73386Z"
      stroke="#E4E4FF"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EditIcon;
