import { LikeButtonProps } from './LikeButton';

import { theme } from '../../../../styles/theme';
import { Button } from '../Button';

import styled, { css } from 'styled-components';

export const Wrapper = styled(Button)<LikeButtonProps>`
  width: 54px;
  height: 52px;

  & svg {
    width: 24px;
    height: 24px;

    ${(props) =>
      props.isLike &&
      css`
        fill: ${theme.colors.red.red_light};
        & path {
          stroke: ${theme.colors.red.red_light};
        }
      `}
  }
`;
