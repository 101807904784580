import { SearchWrapper, SearchButton, SearchSection, Title, SearchInput } from './Search.styled';

import SearchIcon from '../../components/SvgIcons/SearchIcon';
import { useActions } from '../../hooks/useActions';

import { useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export const Search = () => {
  const { t } = useTranslation('pages');
  const navigate = useNavigate();
  const search = useRef<HTMLInputElement | null>(null);
  const { showNotification } = useActions();

  const onSearch = () => {
    const value = search.current?.value;
    if (value) {
      navigate(`results?search=${value}`);
    } else {
      showNotification({ severity: 'error', text: t('search.empty_input') });
    }
  };
  const onEnter = (e: unknown) => {
    if ((e as KeyboardEvent).key === 'Enter') {
      onSearch();
    }
  };

  useEffect(() => {
    search.current?.focus();
  }, []);
  return (
    <SearchSection>
      <Title>{t('search.title')}</Title>
      <SearchWrapper>
        <SearchInput
          icon={<SearchIcon />}
          ref={search}
          onKeyDown={(e) => onEnter(e)}
          placeholder={t('search.input_placeholder')}
        />

        <SearchButton theme="white" onClick={onSearch}>
          {t('search.search_button')}
        </SearchButton>
      </SearchWrapper>
    </SearchSection>
  );
};
