import styled from 'styled-components';

export const DropDownMenuItemStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
  padding: 10px 24px;
`;

export const DropDownMenuItemIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
