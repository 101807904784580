const AddVideosIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M3.18721 7.09122H20.8141M7.90946 5.45473V7.09122M16.0919 5.45473V7.09122M21.0014 14.7009V9.30048C21.0014 4.80014 19.2012 3 14.7009 3H9.30048C4.80014 3 3 4.80014 3 9.30048V14.7009C3 19.2012 4.80014 21.0014 9.30048 21.0014H14.7009C19.2012 21.0014 21.0014 19.2012 21.0014 14.7009Z"
      stroke="#E4E4FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9 14H15" stroke="#E4E4FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 11V17" stroke="#E4E4FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default AddVideosIcon;
