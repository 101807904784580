import { TitleH3, TitleH4, TitleH5, theme } from '../../styles';

import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding: 60px 0;
  width: 100%;
  align-self: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
`;

export const Title = styled(TitleH3)`
  text-transform: capitalize;
  a {
    text-transform: none;
  }
`;
export const SubTitle = styled(TitleH5)``;

export const Description = styled.div`
  font-weight: 500;
  font-size: 28px;
  line-height: 140%;

  color: ${theme.colors.white.white_100};

  & p {
    margin-bottom: 20px;
  }
  a {
    color: ${theme.colors.white.white_40};
  }
`;

export const List = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 10px;
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;

  & > li {
    list-style: inherit;
    margin-left: 20px;

    & li {
      margin-bottom: 20px;
    }
  }
  & ol {
    margin-top: 10px;
  }
  ${(props) =>
    props.lang === 'ru' &&
    css`
      & > li:nth-child(1)::marker {
        content: 'а) ';
      }
      & > li:nth-child(2)::marker {
        content: 'б) ';
      }
      & > li:nth-child(3)::marker {
        content: 'в) ';
      }
      & > li:nth-child(4)::marker {
        content: 'г) ';
      }
    `}
`;

export const ListMarker = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;

  & > li {
    list-style: circle;
    margin-left: 30px;
  }
  & > li:first-child {
    margin-top: 20px;
  }
`;

export const ListNoMarker = styled(List)`
  & > li {
    list-style-type: none;
  }
`;

export const ListTitle = styled(TitleH4)``;
