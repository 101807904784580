const ExitIcon = () => (
  <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M8.23529 11.0105L20 11.0105M20 11.0105L16.6471 14.3333M20 11.0105L16.6471 7.66667M12.7556 5.44444C12.2672 2.30397 10.5149 1 7.01412 1L6.89177 1C2.68471 1 1 2.88025 0.999999 7.57563L0.999999 14.4244C0.999998 19.1197 2.6847 21 6.89176 21L7.01412 21C10.5149 21 12.2692 19.6975 12.7581 16.5556"
      stroke="#E4E4FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ExitIcon;
