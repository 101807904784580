import { Button } from '../Buttons';

import { useTranslation } from 'react-i18next';

import { playlistsAPI } from '@/api';
import { useActions, useHandlingError } from '@/hooks';

interface SuggestVideoButtonProps {
  publicId: string;
  previouslySuggestedVideos: string[];
}

export const SuggestVideoButton = (props: SuggestVideoButtonProps) => {
  const { t } = useTranslation('modal');
  const { openModal } = useActions();
  const { catchError } = useHandlingError();

  const [getSuggestionVideos] = playlistsAPI.useLazyGetSuggestionVideosQuery();

  const onSuggestClick = async () => {
    try {
      openModal({ active: 'SuggestVideo' });
      await getSuggestionVideos(props).unwrap();
    } catch (e) {
      catchError(e);
    }
  };
  return (
    <>
      <Button onClick={onSuggestClick} width="100%">
        {t('suggest_video')}
      </Button>
    </>
  );
};
