import AvatarWithMenu from './AvatarMenu/AvatarWithMenu';
import { ButtonsWrapper, HeaderStyled, Inner, InputWrapper } from './Header.styled';

import { useActions } from '../../hooks/useActions';
import { useAppSelector } from '../../hooks/useStore';
import { ModalType } from '../../store/slices/modalsSlice';
import Button from '../Shared/Buttons/Button/Button';
import SearchButton from '../Shared/Buttons/SearchButton/SearchButton';
import Container from '../Shared/Container/Container';
import Logo from '../Shared/Logo/Logo';
import SearchInput from '../Shared/SearchInput/SearchInput';
import CrossIcon from '../SvgIcons/CrossIcon';
import PlusBig from '../SvgIcons/PlusBigIcon';

import { useState } from 'react';

import { useTranslation } from 'react-i18next';

const Header = () => {
  const { resetSearch, openModal } = useActions();
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const [searchInputMobileIsOpen, setSearchInputMobileIsOpen] = useState<boolean>(false);

  const { t } = useTranslation('header');

  const inputMobileCloseHandler = () => {
    setSearchInputMobileIsOpen(false);
    resetSearch();
  };

  const openModalHandler = (modal: ModalType) => {
    if (isAuth) {
      openModal({ active: modal });
    } else {
      openModal({ active: 'login' });
    }
  };

  return (
    <HeaderStyled>
      <Container>
        <Inner>
          <Logo />

          <InputWrapper isOpen={searchInputMobileIsOpen}>
            <SearchInput />
            <Button variant="withIcon" onClick={inputMobileCloseHandler} theme="transparent">
              <CrossIcon />
            </Button>
          </InputWrapper>

          <ButtonsWrapper>
            <SearchButton onClick={() => setSearchInputMobileIsOpen(true)} />
            <Button theme="black" onClick={() => openModalHandler('AddVideo')}>
              {t('addVideo')}
            </Button>
            <Button theme="white" onClick={() => openModalHandler('AddPlayList')}>
              {t('createPlaylist')}
            </Button>
            <Button variant="withIcon" theme="transparent" onClick={() => openModalHandler('AddMobile')}>
              <PlusBig />
            </Button>
          </ButtonsWrapper>

          <AvatarWithMenu />
        </Inner>
      </Container>
    </HeaderStyled>
  );
};

export default Header;
