import { theme } from '../../../../styles/theme';

import styled from 'styled-components';

export const Wrapper = styled.button`
  width: 54px;
  height: 52px;
  background: ${theme.colors.blue.blue_20};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
