import { TitleH3 } from '../../styles/components';
import { theme } from '../../styles/theme';

import styled from 'styled-components';

type TabsSwitcherProps = {
  switchPosition?: number;
  isActive?: boolean;
};

export const Wrapper = styled.ul<TabsSwitcherProps>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 282px;
  position: relative;

  ::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: ${(props) => (props.switchPosition ? '50%' : '0%')};
    width: 50%;
    height: 2px;
    background-color: ${theme.colors.white.white_100};
    transition: all 0.3s;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;
export const SwitchItem = styled.li<TabsSwitcherProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  padding: 10px;
  /* border-bottom: 2px solid ${theme.colors.white.white_10}; */
  cursor: pointer;
  h3 {
    color: ${(props) => (props.isActive ? theme.colors.white.white_100 : theme.colors.white.white_40)};
  }
`;

export const SwitchTitle = styled(TitleH3)<TabsSwitcherProps>`
  font-size: 17px;
`;
