import { AvatarProps } from './Avatar.props';

import { theme } from '../../../styles/theme';

import styled, { css } from 'styled-components';

// const size = {
//   xs: '36px',
//   s: '50px',
//   m: '180px',
//   l: '',
//   xl: '180px',
// };

export const AvatarStyled = styled.div<AvatarProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  background: ${(props) => (props.url ? `url(${props.url})` : theme.colors.blue.blue_5)};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  font-weight: 400;
  font-size: 17px;
  line-height: 140%;
  color: ${(props) => (props.url ? 'transparent' : theme.colors.white.white_100)};

  ${(props) => {
    switch (props.size) {
      case 'm':
        return css`
          min-width: 180px;
          min-height: 180px;
          font-weight: 700;
          font-size: 48px;
          border-radius: 20px;

          @media screen and (max-width: 480px) {
            min-width: 150px;
            min-height: 150px;
          }
        `;
      case 's':
        return css`
          min-width: 50px;
          min-height: 50px;
        `;
      case 'xs':
        return css`
          min-width: 36px;
          min-height: 36px;
        `;
    }
  }}

  @media screen and (max-width: 1024px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 360px) {
  }
`;
