import styled from 'styled-components';

export const AvatarWrapper = styled.div<{ open: boolean }>`
  border-radius: 100px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  position: relative;

  & .arrow {
    transition: all 0.3s;
    ${(props) => (props.open ? 'transform: rotate(180deg);' : '')}
  }
`;

export const AvatarMenuArrow = styled.button``;
