import { QuizVariantWrapper } from './QuizVariant.styled';

import { Button } from '@/components/Shared/Button';
import MyInput from '@/components/Shared/MyInput/MyInput';
import TickIcon from '@/components/SvgIcons/TickIcon';
import TrashIcon from '@/components/SvgIcons/TrashIcon';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { removeAnswer, toggleCorrect, updateAnswer } from '@/store/slices/quizAdminSlice';
import { RootState } from '@/store/store';
import { QuizAnswersType } from '@/types/adminTypes';

export const QuizVariant = ({ answer, questionIndex, id }: QuizAnswersType & { questionIndex: number; id: number }) => {
  const dispatch = useAppDispatch();
  const isCorrect = useAppSelector(
    (state: RootState) => state.quizAdmin.questions[questionIndex].answers[id].isCorrect,
  );

  const handleRemoveAnswer = () => {
    dispatch(removeAnswer({ questionIndex: questionIndex, answerIndex: id }));
  };

  const handleToggleCorrect = () => {
    dispatch(toggleCorrect({ questionIndex: questionIndex, answerIndex: id }));
  };

  const handleUpdateAnswer = (e: { target: { value: any } }) => {
    dispatch(updateAnswer({ questionIndex: questionIndex, answerIndex: id, newAnswer: e.target.value }));
  };

  return (
    <QuizVariantWrapper>
      <MyInput placeholder="" value={answer} onChange={handleUpdateAnswer} />
      <Button
        theme="inline"
        style={isCorrect ? { padding: '12px', borderColor: '#7AEF8D' } : { padding: '12px' }}
        onClick={handleToggleCorrect}
      >
        <TickIcon />
      </Button>
      <Button theme="inline" style={{ padding: '12px' }} onClick={handleRemoveAnswer}>
        <TrashIcon />
      </Button>
    </QuizVariantWrapper>
  );
};
