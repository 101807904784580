const YouTubeIcon = () => (
  <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M19.5888 2.18648C19.4746 1.76184 19.2505 1.37478 18.9391 1.06427C18.6278 0.753762 18.2401 0.530759 17.8151 0.417715C16.2552 -3.89028e-07 10.0008 0 10.0008 0C10.0008 0 3.74643 -3.89028e-07 2.18651 0.417715C1.7626 0.531811 1.37609 0.755247 1.06568 1.06566C0.75526 1.37607 0.531827 1.76257 0.41773 2.18648C9.57019e-06 3.74639 0 7 0 7C0 7 9.57019e-06 10.2536 0.41773 11.8135C0.531827 12.2374 0.75526 12.6239 1.06568 12.9343C1.37609 13.2448 1.7626 13.4682 2.18651 13.5823C3.74643 14 10.0008 14 10.0008 14C10.0008 14 16.2552 14 17.8151 13.5823C18.2389 13.4682 18.6252 13.2448 18.9354 12.9344C19.2455 12.6239 19.4686 12.2374 19.5823 11.8135C20 10.2536 20 7 20 7C20 7 20.0065 3.74639 19.5888 2.18648ZM8.00359 10.0007V3.9993L13.2022 7L8.00359 10.0007Z"
      fill="#E4E4FF"
    />
  </svg>
);

export default YouTubeIcon;
