const SearchIcon = () => (
  <svg xmlns="https://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M10 16C13.3137 16 16 13.3137 16 10C16 6.68629 13.3137 4 10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16Z"
      stroke="#E4E4FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M20.0002 20L14.2402 14.25" stroke="#E4E4FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SearchIcon;
