import { ButtonText, theme } from '../../../../styles';
import { Button } from '../Button';

import styled from 'styled-components';

export const GoBackButtonStyled = styled(Button)`
  padding: 0;
  margin: 27px 0 0 6px;
  :hover {
    path {
      stroke: ${theme.colors.white.white_80};
      transition: all 0.5s ease;
    }
    p {
      color: ${theme.colors.white.white_80};
      transition: all 0.5s ease;
    }
  }
`;

export const BackLink = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const BackLinkText = styled(ButtonText)``;
