import { InputWrapper, TextAreaWrapper } from './InputStyle';

import { CSSProperties, ForwardedRef, InputHTMLAttributes, forwardRef } from 'react';

type InputProps = InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> & {
  rows?: number;
  wrap?: 'hard' | 'soft' | 'off';
  sx?: CSSProperties;
};

const Input = forwardRef(
  ({ type = 'text', name, id, ...props }: InputProps, ref: ForwardedRef<HTMLInputElement | HTMLTextAreaElement>) => {
    if (type === 'textarea') {
      return (
        <TextAreaWrapper
          sx={props.sx}
          id={id || name}
          name={name}
          {...props}
          ref={ref as ForwardedRef<HTMLTextAreaElement>}
        />
      );
    }
    return (
      <InputWrapper
        sx={props.sx}
        id={id || name}
        name={name}
        type={type}
        {...props}
        ref={ref as ForwardedRef<HTMLInputElement>}
      />
    );
  },
);

Input.displayName = 'Input';

export default Input;
