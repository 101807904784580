import styled from 'styled-components';

export const Buttons = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const TimecodesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  align-items: flex-start;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
`;

export const VideoTimecodesWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 30px;
  justify-content: center;
  align-items: flex-start;
`;

export const TimecodesButtonWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
`;
