import { BookmarkButtonProps } from './BookmarkButton';

import { theme } from '../../../../styles/theme';

import styled, { css } from 'styled-components';

export const Wrapper = styled.button<BookmarkButtonProps>`
  width: 54px;
  height: 52px;
  ${(props) => {
    switch (props.bg) {
      case 'red':
        return css`
          background: ${theme.colors.red.red_light};
          & path {
            stroke: ${theme.colors.blue.blue_20};
          }
        `;

      case 'blue':
        return css`
          background: ${theme.colors.blue.blue_20};
          & path {
            stroke: ${theme.colors.white.white_100};
          }
        `;
      case 'white':
        return css`
          background: ${theme.colors.white.white_100};
          & path {
            stroke: ${theme.colors.blue.blue_20};
          }
        `;
      case 'none':
        return css`
          background: transparent;
          & path {
            stroke: ${theme.colors.blue.blue_20};
          }
        `;
      default:
    }
  }}
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    width: 24px;
    height: 24px;

    ${(props) =>
      props.choose &&
      css`
        fill: ${theme.colors.white.white_100};
        & path {
          stroke: ${theme.colors.white.white_100};
        }
      `}
  }
`;
