import Input from '../../Input/Input';
import { Form, Label, PlayListImage, ButtonsWrapper } from '../CreatePlaylist/CreatePlaylist.styled';
import { usePlaylist } from '../CreatePlaylist/hooks';
import { Modal, Inner, Title } from '../Modal';

import { FormEvent, useMemo } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Alert, Collapse, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { playlistsAPI } from '@/api';
import { FullScreenLoader, Button } from '@/components';
import { useAppSelector } from '@/hooks';
import { SecondaryText, SelectWrapper } from '@/styles';
import { Playlist } from '@/types';

interface EditPlaylistProps {
  id?: string;
}

const plData = (data: Playlist | undefined) =>
  data
    ? {
        title: data.title,
        description: data.description,
        category: data.category.publicId,
        privacyType: data.privacyType,
      }
    : undefined;

export const EditPlaylist = ({ id }: EditPlaylistProps) => {
  const { t } = useTranslation('modal');

  const [updatePL, result] = playlistsAPI.useUpdatePartialPlaylistByIdMutation();

  const auth = useAppSelector((state) => state.user.isAuth);

  const { data: userPlaylist, isLoading } = playlistsAPI.useGetPlaylistByIdQuery(
    { id: id ?? '' },
    {
      skip: !auth,
    },
  );

  const currentPlaylistData = useMemo(() => plData(userPlaylist), [userPlaylist]);

  const {
    playlist,
    categories,
    errorAlert,
    setErrorAlert,
    categoryOptions,
    privacyOptions,
    titleInputHandler,
    descriptionInputHandler,
    selectCategoryHandler,
    selectAccessHandler,
    closeHandler,
    resetError,
    showNotification,
    catchError,
  } = usePlaylist(currentPlaylistData);

  const updatePlayList = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (playlist.title) {
      const payload = await updatePL({
        id: id ?? '',
        body: {
          title: playlist.title,
          description: playlist.description,
          category: playlist.category,
          privacyType: playlist.privacyType,
        },
      });

      if ('data' in payload) {
        closeHandler({
          title: playlist.title,
          description: playlist.description,
          category: playlist.category,
          privacyType: playlist.privacyType,
        });
        showNotification({
          text:
            payload.data.title !== playlist.title
              ? t('ed_pl_name', { ns: 'success', name: payload.data.title, editName: playlist.title })
              : t('ed_pl', { ns: 'success', editName: playlist.title }),
          severity: 'success',
        });
      } else {
        catchError(payload, t('no_save_ch', { ns: 'error' }));
      }
    } else {
      setErrorAlert({
        open: true,
        message: t('req_field', { ns: 'error' }) as string,
      });
    }
  };
  const currentCategory = categories.find((el) => el.publicId === playlist.category);

  return (
    <Modal modal={'EditPlayList'} closeButton>
      <Title>{t('menu.set_pl', { ns: 'components' })}</Title>
      <Inner modal={'EditPlayList'}>
        <Form onSubmit={updatePlayList}>
          <Collapse in={errorAlert.open}>
            <Alert
              severity="error"
              variant="filled"
              action={
                <IconButton aria-label="close" color="inherit" size="small" onClick={resetError}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {errorAlert.message}
            </Alert>
          </Collapse>
          <PlayListImage
            src={currentCategory?.image ?? '/images/playlist_mock.png'}
            alt={currentCategory?.name ?? ''}
          />
          <Label>
            <SecondaryText>{t('category')}</SecondaryText>
            <SelectWrapper>
              <Select
                id="selectId"
                className="basic-single"
                classNamePrefix="select"
                options={categoryOptions}
                defaultValue={categoryOptions.find((el) => el.value === playlist.category)}
                isDisabled={false}
                // isLoading={Boolean(selectIsLoading)}
                isSearchable={false}
                name="color"
                onChange={selectCategoryHandler}
              />
            </SelectWrapper>
          </Label>
          <Label>
            <SecondaryText>{`${t('pl_name')} *`}</SecondaryText>
            <Input defaultValue={playlist.title} onChange={titleInputHandler} placeholder={t('pl_name_ph')} />
          </Label>
          <Label>
            <SecondaryText>{t('descr')}</SecondaryText>
            <Input
              defaultValue={playlist.description}
              onChange={descriptionInputHandler}
              placeholder={t('descr_ph')}
              type="textarea"
            />
          </Label>
          <Label>
            <SecondaryText>{t('priv_set')}</SecondaryText>
            <SelectWrapper>
              <Select
                id="selectAccess"
                className="basic-single"
                classNamePrefix="select"
                options={privacyOptions}
                defaultValue={privacyOptions.find((el) => el.value === playlist.privacyType)}
                isDisabled={false}
                // isLoading={Boolean(selectIsLoading)}
                isSearchable={false}
                name="color"
                menuPlacement="top"
                onChange={selectAccessHandler}
              />
            </SelectWrapper>
          </Label>
          <ButtonsWrapper>
            <Button theme="white" type="submit">
              {t('form.save', { ns: 'components' })}
            </Button>
            <Button onClick={() => closeHandler()} type="button">
              {t('form.cancel', { ns: 'components' })}
            </Button>
          </ButtonsWrapper>
          {(result.isLoading || isLoading) && <FullScreenLoader />}
        </Form>
      </Inner>
      {/* <ExitButtonWrapper type="reset" onClick={closeHandler}>
            <CrossIcon />
          </ExitButtonWrapper> */}
    </Modal>
  );
};
