const BookmarkIcon = () => (
  <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M7.85714 1H2.14286C1.83975 1 1.54906 1.14048 1.33474 1.39052C1.12041 1.64057 1 1.97971 1 2.33333V13L5 11L9 13V2.33333C9 1.97971 8.87959 1.64057 8.66527 1.39052C8.45094 1.14048 8.16025 1 7.85714 1Z"
      stroke="#BBB6DA"
      strokeLinejoin="round"
    />
  </svg>
);
export default BookmarkIcon;
