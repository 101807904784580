const ShareIconBig = () => (
  <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M9.18883 2.18043L8.98396 5.45386L8.96377 5.88803L8.87294 5.89844C4.39401 6.43984 1 10.3796 1 15.2044C1 15.6802 1.03431 16.1519 1.10294 16.6173C1.38451 18.5382 1.59645 18.7246 2.89128 17.1358C3.21219 16.7493 3.56322 16.3907 3.94087 16.0634C5.29618 14.9076 6.98171 14.2426 8.73872 14.1705L8.9668 14.1653L9.02837 15.4449L9.1959 17.8864C9.27663 18.7235 10.1304 19.2951 10.9156 18.8381C11.2709 18.6319 11.617 18.4112 11.9652 18.1925C13.7687 17.0285 15.4571 15.6906 17.1021 14.3007C18.071 13.4677 18.9187 12.6785 19.6413 11.9601C19.9744 11.6238 20.3074 11.2875 20.6294 10.9398C21.0956 10.4358 21.1421 9.60187 20.6536 9.074C19.5293 7.86104 17.9408 6.34197 15.9637 4.73025C14.3611 3.43712 12.704 2.22936 10.9479 1.16841C10.1032 0.671777 9.26553 1.3527 9.18883 2.18043V2.18043Z"
      stroke="#E4E4FF"
      strokeWidth="2"
    />
  </svg>
);

export default ShareIconBig;
