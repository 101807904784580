import { Title, VideoWrapper } from './VideoModalStyled';

import FullScreenLoader from '../../FullScreenLo/FullScreenLoader';
import { Inner, Modal } from '../Modal';

import YouTube from 'react-youtube';

import { videosAPI } from '@/api';
import { useActions, useAppSelector } from '@/hooks';

export const VideoModal = () => {
  const { openModal } = useActions();

  const { data } = useAppSelector((state) => state.modal);
  const closeHandler = () => {
    openModal({ active: 'SuggestVideo' });
  };

  const { data: video, isLoading } = videosAPI.useGetMovieByIdQuery({ id: data?.id || '' }, { skip: !data });

  return (
    <Modal modal={'Video'} onClose={closeHandler} closeButton>
      <Title>{video?.title}</Title>
      <Inner modal={'Video'}>
        <VideoWrapper>
          {isLoading && <FullScreenLoader />}
          {video && (
            <YouTube
              videoId={video.videoId}
              title={video.title}
              style={{ width: '100% ', height: '400px' }}
              opts={{
                width: '100% ',
                height: '390px',
                playerVars: {
                  autoplay: 1,
                  rel: 0,
                },
              }}
            />
          )}
        </VideoWrapper>
      </Inner>
    </Modal>
  );
};
