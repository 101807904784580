import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from '@/styles';

export const BreadCrumbsList = styled.ol`
  display: flex;
  gap: 10px;
  margin: 27px 0 0 6px;
`;
export const Crumb = styled.li`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: ${theme.colors.text.white_100};

  &:last-child {
    color: ${theme.colors.text.white_40};
  }

  & + &::before {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: ${theme.colors.text.white_100};
    content: '/';
  }
`;
export const BreadCrumbLink = styled(Link)`
  color: ${theme.colors.text.white_100};
  text-decoration: none;

  &:hover {
    color: ${theme.colors.white.white_80};
    transition: all 0.3s ease;
    text-decoration: none;
  }
`;
