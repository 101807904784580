const NotepadIcon = ({ width = 24, height = 24, ...props }: SVGProps) => (
  <svg width={width} height={height} {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.34657 2.00001H15.8394C15.9172 2.00001 15.9945 2.0049 16.0714 2.01468C19.1214 2.22422 21.4897 4.71093 21.5 7.71467V16.2867C21.4888 19.4494 18.8727 22.0059 15.6534 22H8.34657C5.12676 22.0059 2.51048 19.4486 2.5 16.2853V7.71467C2.51048 4.55137 5.12676 1.99411 8.34657 2.00001Z"
      stroke="#E4E4FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.8972 6.17186L6.8737 7.77186C6.67824 7.95486 6.5683 8.209 6.56984 8.47426C6.57138 8.73952 6.68427 8.9924 6.88184 9.1732L8.18877 10.7185L13.6526 17.1732C13.7437 17.2771 13.8657 17.3504 14.0014 17.3825L16.7157 17.9919C17.0746 18.0118 17.3871 17.7534 17.4282 17.4025L17.302 14.8079C17.2927 14.6747 17.2382 14.5484 17.1473 14.4492L11.8096 8.14253L10.3711 6.4452C10.0247 5.99479 9.38682 5.87649 8.8972 6.17186Z"
      stroke="#E4E4FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.5002 8.51895C21.9144 8.51895 22.2502 8.18317 22.2502 7.76895C22.2502 7.35474 21.9144 7.01895 21.5002 7.01895V8.51895ZM16.0716 7.76895H15.3216C15.3216 8.18317 15.6574 8.51895 16.0716 8.51895V7.76895ZM16.8216 2.01562C16.8216 1.60141 16.4859 1.26562 16.0716 1.26562C15.6574 1.26562 15.3216 1.60141 15.3216 2.01562H16.8216ZM12.5485 8.27938C12.6209 7.87153 12.3489 7.48227 11.941 7.40996C11.5332 7.33764 11.1439 7.60964 11.0716 8.0175L12.5485 8.27938ZM8.29811 9.97305C7.88829 9.9129 7.50731 10.1964 7.44716 10.6062C7.38701 11.016 7.67048 11.397 8.0803 11.4572L8.29811 9.97305ZM21.5002 7.01895H16.0716V8.51895H21.5002V7.01895ZM16.8216 7.76895V2.01562H15.3216V7.76895H16.8216ZM11.0716 8.0175C10.8466 9.28637 9.62417 10.1677 8.29811 9.97305L8.0803 11.4572C10.1862 11.7662 12.178 10.3691 12.5485 8.27938L11.0716 8.0175Z"
      fill="#E4E4FF"
    />
  </svg>
);

export default NotepadIcon;
