import { ImageWrapper } from '../PlaylistCard';

import styled from 'styled-components';

import { DescriptionText } from '@/styles';

export const VideoCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  animation: skale-in 0.4s;

  @keyframes skale-in {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

export const ImageFragmentWrapper = styled(ImageWrapper)`
  height: 200px;
`;

export const InnerCard = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 5px;
  gap: 5px;
`;

export const PlayButton = styled.button`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const VideoTime = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #e4e4ff;
  padding: 9px 12px;
  display: flex;
  background: rgba(23, 8, 123, 0.3);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  width: max-content;
  align-items: center;
  user-select: none;
  margin-left: auto;
`;

export const FragmentTime = styled(VideoTime)`
  font-size: 17px;
  line-height: 24px;
  border-radius: 10px;
  padding: 0 6px;
  background: rgba(93, 95, 239, 1);
  word-wrap: break-word;
  margin: 0;
`;

export const Title = styled(DescriptionText)`
  font-size: 17px;
  font-weight: 500;
  line-clamp: 2;
  hyphens: none;
  overflow: hidden;
  text-overflow: ellipsis; /* Добавляем многоточие */
  white-space: break-spaces; //Запрещаем перенос строк
  max-height: 60px;
`;
