import { Wrapper } from './PlusButton.styled';

import PlusBig from '../../../SvgIcons/PlusBigIcon';

import { MouseEvent } from 'react';

interface PlusButtonProps {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  children?: React.ReactNode;
}

const PlusButton = ({ onClick, children }: PlusButtonProps) => {
  return (
    <Wrapper onClick={onClick} variant="withIcon">
      <PlusBig />
      {children}
    </Wrapper>
  );
};

export default PlusButton;
