import { Button, Inner } from './AddMobile.styled';

import { useActions } from '../../../../hooks/useActions';
import { DescriptionText } from '../../../../styles';
import AddVideosIcon from '../../../SvgIcons/AddVideosIcon';
import CreatePlaylistIcon from '../../../SvgIcons/CreatePlaylistIcon';
import { Modal } from '../Modal/Modal';

import { useTranslation } from 'react-i18next';

const AddMobile = () => {
  const { openModal } = useActions();
  const { t } = useTranslation('header');

  const CreatePlayListHandler = () => {
    openModal({ active: 'AddPlayList' });
  };

  const AddVideoHandler = () => {
    openModal({ active: 'AddVideo' });
  };

  return (
    <Modal modal={'AddMobile'} closeButton={false}>
      <Inner>
        <Button onClick={CreatePlayListHandler}>
          <CreatePlaylistIcon />
          <DescriptionText>{t('createPlaylist')}</DescriptionText>
        </Button>
        <Button onClick={AddVideoHandler}>
          <AddVideosIcon />
          <DescriptionText>{t('addVideo')}</DescriptionText>
        </Button>
      </Inner>
    </Modal>
  );
};

export default AddMobile;
