import { lazy } from 'react';

export { default as Main } from './MainPage/MainPage';
export { default as User } from './UserPage/UserPage';
export { default as Profile } from './ProfilePage/ProfilePage';
export { Library } from './LibraryPage';
export { default as PlayListOutlet } from './PlayListOutlet/PlayListOutlet';
export { default as Playlist } from './PlayListPage/PlayListPage';
export { default as PlaylistPrivate } from './PlaylistPrivatePage/PlaylistPrivatePage';
export { default as PlaylistView } from './PlayListViewPage/PlaylistViewPage';
export { default as Video } from './VideoPage/VideoPage';
export { default as Statistic } from './StatisticPage/StatisticPage';
export { default as Onboarding } from './OnboardingPage/OnboardingPage';
export { default as NotFoundPage } from './NotFoundPage/NotFoundPage';
export { default as SearchResult } from './Search/SearchResultPage/SearchResultPage';
export { default as Admin } from './AdminPage/AdminPage';
export const Activate = lazy(() => import('./Activate/Activate'));
export const Password = lazy(() => import('./Password/Password'));
export * from './PolicyPage';
export * from './Search/SearchPage';
export { default as ErrorPage } from './ErrorPage/ErrorPage';

export const OnboardingLazy = lazy(() => import('./OnboardingPage/OnboardingPage'));
export const AdminLazy = lazy(() => import('./AdminPage/AdminPage'));
export const AdminVideoOutletLazy = lazy(() => import('./AdminPage/AdminVideoOutlet/AdminVideoOutlet'));
