import { TitleH2 } from '../../styles/components';

import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 60px 0;
  width: 100%;
  align-self: center;
  height: 100%;
  background: #0b002c;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  @media screen and (max-width: 1024px) {
    padding-left: 180px;
  }
  @media screen and (max-width: 768px) {
    padding-left: 0;
    margin: auto;
    width: max-content;
  }
  @media screen and (max-width: 480px) {
    padding-left: 0;
    margin: 0;
    width: 100%;
  }
`;

export const Title = styled(TitleH2)`
  font-style: normal;
  font-weight: 500;
  font-size: 100px;
  line-height: 140%;
`;
export const Description = styled.p`
  font-weight: 500;
  font-size: 28px;
  line-height: 140%;

  color: #ffffff;
`;
