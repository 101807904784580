import { usersAPI } from '../../../../api/usersApi';
import { useActions } from '../../../../hooks/useActions';
import { useHandlingError } from '../../../../hooks/useErrorHandling';
import { isAuthData } from '../../../../utils/isAuthData';
import Button from '../../Buttons/Button/Button';
import FullScreenLoader from '../../FullScreenLo/FullScreenLoader';
import MyInput from '../../MyInput/MyInput';
import { ButtonsWrapper, FieldsWrapper, Form } from '../Login/Login.styled';
import { Modal, Title } from '../Modal';
import { Inner } from '../RemoveUser/RemoveUser.styled';

import { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertTitle, Collapse, IconButton } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

export const ResendEmail = () => {
  const { t } = useTranslation('components');
  const { closeModal, openModal } = useActions();
  const [, setParams] = useSearchParams();

  const { catchError } = useHandlingError();

  const [resetEmail, status] = usersAPI.useResendActivationMutation();

  const mess = t('set_pass_reset_lint', { ns: 'error' });
  const [errorAlertIsOpen, setErrorAlertIsOpen] = useState<boolean>(status.isError);
  const [errorAlertMessage, setErrorAlertMessage] = useState<string>(mess);

  const closeHandler = () => {
    reset();
    setErrorAlertIsOpen(false);
    closeModal();
    setParams();
  };

  const {
    register,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
  } = useForm<{ email: string }>({ defaultValues: { email: '' } });

  const onSubmit = async (data: { email: string }) => {
    try {
      await resetEmail(data).unwrap();
      openModal({ active: 'emailverify', data });
    } catch (err) {
      console.log(err);

      if (isAuthData(err)) {
        const words = err.data[0].split(' ');
        const email = getValues('email');
        const message = words.includes('email')
          ? t('inverify_email', { ns: 'error', email })
          : t('incor_cred', { ns: 'error' });
        setErrorAlertMessage(message);
        setErrorAlertIsOpen(true);
      }
      catchError(err);
    }
  };

  return (
    <Modal modal={'resendEmail'} onClose={closeHandler} closeButton>
      <Title>{t('res_email', { ns: 'modal' })}</Title>
      <Inner>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Collapse in={errorAlertIsOpen}>
            <Alert
              severity="error"
              variant="filled"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setErrorAlertIsOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              <AlertTitle>{t('log', { ns: 'error' })}</AlertTitle>
              {errorAlertMessage}
            </Alert>
          </Collapse>
          <FieldsWrapper>
            <MyInput
              required
              label={t('form.email')}
              {...register('email', {
                required: { value: true, message: t('req_field', { ns: 'error' }) },
                minLength: { value: 5, message: t('min_char', { ns: 'error', length: 5 }) },
                maxLength: { value: 256, message: t('max_char', { ns: 'error', length: 256 }) },
                pattern: {
                  value: /^([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*\.[a-z]{2,6}$/,
                  message: t('incor_email', { ns: 'error' }),
                },
              })}
              // placeholder={t('form.email')}
              error={errors.email}
            />
          </FieldsWrapper>
          <ButtonsWrapper>
            <Button theme="white" type="submit">
              {/* {t('menu.in', { ns: 'header' })} */}
              {t('form.send')}
            </Button>
            <Button theme="inline" type="reset" onClick={closeHandler}>
              {t('form.cancel')}
            </Button>
          </ButtonsWrapper>
        </Form>
        {status.isLoading && <FullScreenLoader />}
      </Inner>
    </Modal>
  );
};
