import { Main, Wrapper } from '../../App.styled';
import Container from '../../components/Shared/Container/Container';

import styled from 'styled-components';

export const SearchContainer = styled(Container)`
  padding: 0 2px;
`;

export const SearchWrapper = styled(Wrapper)`
  grid-template-rows: auto 480px min-content auto;
  gap: 22px;
  background: url('/images/bg/ellipse-5.png') no-repeat 50% 82%;
  @media screen and (max-width: 1000px) {
    background: url('/images/bg/ellipse-5.png') no-repeat 40% 45%;

    grid-template-rows: 80px 410px 418px;
  }
`;
export const SearchMain = styled(Main)`
  background: url('/images/bg/5.png') no-repeat 98% 82%, url('/images/bg/12.png') no-repeat 100% 15%,
    url('/images/bg/20.png') no-repeat 0% -2767%, url('/images/bg/21.png') no-repeat 0% 157%;

  @media screen and (max-width: 1000px) {
    background: url('/images/bg/5s.png') no-repeat 0% 25%, url('/images/bg/20r.png') no-repeat 100% 110%,
      url('/images/bg/21s.png') no-repeat 0% 110%;
  }
`;

export const ResultWrapper = styled(Wrapper)`
  grid-template-rows: auto 1fr auto;
  gap: 22px;
`;
