const InfoIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
      stroke="#E4E4FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.249 16.25C11.249 16.6642 11.5847 17 11.999 17C12.4132 17 12.749 16.6642 12.749 16.25V11.25C12.749 10.8358 12.4132 10.5 11.999 10.5C11.5847 10.5 11.249 10.8358 11.249 11.25V16.25ZM12.004 9.25C12.5563 9.25 13.004 8.80229 13.004 8.25C13.004 7.69771 12.5563 7.25 12.004 7.25H11.995C11.4427 7.25 10.995 7.69771 10.995 8.25C10.995 8.80229 11.4427 9.25 11.995 9.25H12.004Z"
      fill="#E4E4FF"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);

export default InfoIcon;
