import { SearchCard } from '../../../components/Card';
import FullScreenLoader from '../../../components/Shared/FullScreenLo/FullScreenLoader';
import { CardsList, CountTitle } from '../SearchResultPage/SearchResultPage.styled';

import { useTranslation } from 'react-i18next';

import { Video } from '@/types';

export const ResultInner = ({
  data = [],
  isLoading,
  countVideo = 0,
}: {
  data?: Video[];
  isLoading: boolean;
  countVideo?: number;
}) => {
  const { t } = useTranslation('pages');
  if (isLoading) {
    return <FullScreenLoader />;
  }
  return (
    <>
      <CountTitle>{t('search.count_title', { countVideo })}</CountTitle>
      <CardsList>
        {data.map((video) => (
          <SearchCard key={video.publicId} video={video} />
        ))}
      </CardsList>
    </>
  );
};
