import { Wrapper } from './WithBackIcon.styled';

import { BackIcon } from '@/components/SvgIcons';

interface ShareButtonProps {
  onClick: () => void;
}

const WithBackIcon = ({ onClick }: ShareButtonProps) => {
  return (
    <Wrapper onClick={onClick}>
      <BackIcon />
    </Wrapper>
  );
};

export default WithBackIcon;
