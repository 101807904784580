import { getMinutes } from './helpers';
import { TimecodeDiscription } from './TimecodeDiscription';
import {
  Time,
  TimecodesWrapper,
  Title,
  TimecodesContentWrapper,
  TimecodesTitleWrapper,
  List,
  ListItem,
  Text,
  MoreButton,
  TimecodesTitle,
} from './Timecodes.styled';

import { memo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useGetTimecodesQuery } from '@/api';
import { TimecodeLoader } from '@/pages/VideoPage/components/TimecodeLoader';

interface TimecodesProps {
  setTime: (time: number) => void;
  playlistId: string;
  id: string;
  currentTime: number | null;
}

export const Timecodes = memo(({ setTime, playlistId, id, currentTime }: TimecodesProps) => {
  const { t } = useTranslation('error');
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { data, isLoading, isError } = useGetTimecodesQuery({ playlistId: playlistId, videoPublicId: id });
  // const refs = data?.map((_, i) => useRef<HTMLLIElement>(null));

  const timings = data?.map((array) => array.start) || [];

  const highlightChapter = (i: number) => {
    // TODO: make a scroll to current timecodes
    // if (currentTime && currentTime >= timings[i] && currentTime < timings[i + 1]) {
    //   refs?.[i]?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // }
    return currentTime && currentTime >= timings[i] && (timings[i + 1] === undefined || currentTime < timings[i + 1]);
  };

  const onReadMoreClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <TimecodesWrapper isCollapsed={isCollapsed}>
      {isLoading && <TimecodeLoader t={t} />}
      {isError && <Text>{t('load_timecodes', { ns: 'loading' })}</Text>}
      {/*{isError && <Text>{t('err_timecodes')} <Link to="mailto:contact@visaver.com">contact@visaver.com</Link></Text>}*/}
      {data && (
        <>
          <Title>Таймкоды</Title>
          <List>
            {data.map(({ start, text, title }, i) => (
              <ListItem key={i}>
                {/*  ref={refs?.[i]} */}
                <TimecodesContentWrapper>
                  <TimecodesTitleWrapper onClick={() => setTime(start)}>
                    <Time>{getMinutes(start)}</Time>
                    <TimecodesTitle style={{ backgroundColor: highlightChapter(i) ? '#BE9F4B' : 'transparent' }}>
                      {title}
                    </TimecodesTitle>
                  </TimecodesTitleWrapper>
                  <div />
                  <TimecodeDiscription text={text} />
                </TimecodesContentWrapper>
              </ListItem>
            ))}
          </List>
          <MoreButton theme="inline" onClick={onReadMoreClick}>
            {isCollapsed ? 'Скрыть' : 'Еще'}
          </MoreButton>
        </>
      )}
    </TimecodesWrapper>
  );
});

Timecodes.displayName = 'Timecodes';
