import { TextWrapper, Text, TextLink } from './Timecodes.styled';

import { useState } from 'react';

export const TimecodeDiscription = ({ text }: { text: string }) => {
  const [isCollapsedText, setIsCollapsedText] = useState(true);

  const onReadMoreClick = () => {
    setIsCollapsedText((p) => !p);
  };

  if (text.length < 100)
    return (
      <TextWrapper>
        <Text>{text}</Text>
      </TextWrapper>
    );
  return (
    <TextWrapper>
      {isCollapsedText ? (
        <span>
          <Text>{`${text.slice(0, 100)}...`}</Text>
          <TextLink onClick={onReadMoreClick}>Читать больше</TextLink>
        </span>
      ) : (
        <span>
          <Text>{text}</Text>
          <TextLink onClick={() => setIsCollapsedText((p) => !p)}>Cкрыть</TextLink>
        </span>
      )}
    </TextWrapper>
  );
};
