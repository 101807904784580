import { SortMenuArrow, SortWrapper, SortDropDown } from './SortMenu.styled';

import DropDownMenuItem from '../../../components/Shared/DropDown/DropDownMenu/DropDownIMenuItem';
import ArrowSmall from '../../../components/SvgIcons/ArrowSmall';
import { useLatest } from '../../../hooks/useLatest';
import { DescriptionText } from '../../../styles';
import { AccountStat } from '../../../types/contentTypes';

import { type Reducer, useReducer, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

type Order = 'asc' | 'desc';
type PLKey = Exclude<keyof AccountStat, 'background_image' | 'link' | 'description'>;

type ColumnW = {
  sort: Order | null;
  accessor: PLKey;
  data: AccountStat[];
};

interface SortMenuProps {
  state: ColumnW;
  setSortHandler: (key: PLKey) => void;
}

const SortMenu = ({ state, setSortHandler }: SortMenuProps) => {
  const [opened, setOpened] = useReducer<Reducer<boolean, boolean>>((v: boolean) => !v, false);
  const toggleHandler = () => {
    setOpened(opened);
  };
  const { t } = useTranslation('pages');
  const setIcon = useLatest((key: PLKey) => (
    <>
      {state.sort === 'asc' && state.accessor === key
        ? ' 🠕'
        : state.sort === 'desc' && state.accessor === key
        ? ' 🠗'
        : ''}
    </>
  ));

  const items = useMemo(
    () => [
      {
        text: t('statistics.name'),
        accessor: 'name',
      },
      {
        text: t('video_few', { ns: 'components' }),
        accessor: 'movie_count',
      },
      {
        text: t('statistics.view'),
        accessor: 'views_count',
      },
      {
        text: t('statistics.like'),
        accessor: 'likes_count',
      },
      {
        text: t('statistics.repost'),
        accessor: 'reposts_count',
      },
      {
        text: t('statistics.comment'),
        accessor: 'comments_count',
      },
      {
        text: t('statistics.save'),
        accessor: 'bookmarked_count',
      },
      // <DropDownMenuItem  key="Уведомления" icon={<BellIcon />} text='Уведомления' />,
    ],
    [],
  );

  return (
    // <SortDropDown items={items.current()} isOpen={opened} toggleOpen={toggleHandler}>
    <SortDropDown
      isOpen={opened}
      toggleOpen={toggleHandler}
      items={items.map((item) => (
        <DropDownMenuItem
          key={t(item.text)}
          icon={setIcon.current(item.accessor as PLKey)}
          text={t(item.text)}
          onClick={() => setSortHandler(item.accessor as PLKey)}
        />
      ))}
    >
      <SortWrapper open={opened}>
        <DescriptionText>{t('statistics.sort')}</DescriptionText>
        <SortMenuArrow>
          <ArrowSmall className="arrow" />
        </SortMenuArrow>
      </SortWrapper>
    </SortDropDown>
  );
};

export default SortMenu;
