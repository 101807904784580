import { useEffect, useState } from 'react';

export const useMount = (opened: boolean, delay?: number) => {
  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    if (opened && !mounted) {
      setMounted(true);
    } else if (!opened && mounted) {
      setTimeout(
        () => {
          setMounted(false);
        },
        delay ? delay : 300,
      );
    }
  }, [opened]);

  return { mounted };
};
