import { HeaderTitle, Inner, InnerImage, StatTableStyled, SubTitle, Title, InnerText } from './StatTable.styled';

import { PlaylistStatCard, StatisticsItemText } from '../../../components/Card/PlaylistStatCard';
import { AccountStat } from '../../../types/contentTypes';
import SortMenu from '../SortMenu';
import { List } from '../StatisticPage.styled';

import { memo, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { useMatchMedia, useAppSelector, useSortStatistics } from '@/hooks';

type Column = {
  Header: string;
  accessor: Exclude<keyof AccountStat, 'background_image' | 'link' | 'description'>;
};

interface StatTableProps {
  playlists: AccountStat[];
}

const StatTable = ({ playlists }: StatTableProps) => {
  const isDesktop = useMatchMedia('(min-width: 1025px)');
  const navigate = useNavigate();
  const id = useAppSelector((state) => state.user.user_id);
  const { t } = useTranslation();
  const columns: Column[] = useMemo(
    () => [
      {
        Header: t('pl_one', { ns: 'components' }),
        accessor: 'name',
      },
      {
        Header: t('video_one', { ns: 'components' }),
        accessor: 'movie_count',
      },
      {
        Header: t('statistics.view', { ns: 'pages' }),
        accessor: 'views_count',
      },
      {
        Header: t('statistics.like', { ns: 'pages' }),
        accessor: 'likes_count',
      },
      {
        Header: t('statistics.repost', { ns: 'pages' }),
        accessor: 'reposts_count',
      },
      {
        Header: t('statistics.comment', { ns: 'pages' }),
        accessor: 'comments_count',
      },
      {
        Header: t('statistics.save', { ns: 'pages' }),
        accessor: 'bookmarked_count',
      },
    ],
    [t],
  );

  const { state, setSortHandler } = useSortStatistics(playlists);

  const navigateHandler = (itemId: number) => {
    navigate(`/playlist/${itemId}?u=${id}`);
  };

  if (!isDesktop) {
    return (
      <>
        <SortMenu state={state} setSortHandler={setSortHandler} />
        <List>
          {/* <PlaylistStatCard playlist={card} /> */}
          {state.data.map((playlist) => (
            <Link to={`/playlist/${playlist.id}?u=${id}`} key={playlist.id} style={{ width: 'max-content' }}>
              <PlaylistStatCard playlist={playlist} />
            </Link>
          ))}
        </List>
      </>
    );
  }

  return (
    <StatTableStyled>
      <thead>
        <tr>
          {columns.map((column) => (
            <th key={column.Header}>
              <HeaderTitle onClick={() => setSortHandler(column.accessor)}>
                {column.Header}
                {''}
                {state.sort === 'asc' && state.accessor === column.accessor
                  ? ' 🠕'
                  : state.sort === 'desc' && state.accessor === column.accessor
                  ? ' 🠗'
                  : ''}
              </HeaderTitle>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {state.data.map((item) => (
          <tr key={item.id} onClick={() => navigateHandler(item.id)}>
            <td>
              <Inner>
                <InnerImage bgImage={item.background_image} />
                <InnerText>
                  <Title>{item.name.substring(0, 100)}</Title>
                  <SubTitle>{item.description}</SubTitle>
                </InnerText>
              </Inner>
            </td>
            <td>
              <StatisticsItemText>{item.movie_count}</StatisticsItemText>
            </td>
            <td>
              <StatisticsItemText>{item.views_count}</StatisticsItemText>
            </td>
            <td>
              <StatisticsItemText>{item.likes_count}</StatisticsItemText>
            </td>
            <td>
              <StatisticsItemText>{item.reposts_count}</StatisticsItemText>
            </td>
            <td>
              <StatisticsItemText>{item.comments_count}</StatisticsItemText>
            </td>
            <td>
              <StatisticsItemText>{item.bookmarked_count}</StatisticsItemText>
            </td>
          </tr>
        ))}
      </tbody>
    </StatTableStyled>
  );
};

export default memo(StatTable);
