const CreatePlaylistIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M6 5.28947H18M9 2H15M17 22H7C4 22 2 20.6579 2 17.5263L2 12.8373C2 9.70574 4 8.36364 7 8.36364H17C20 8.36364 22 9.70574 22 12.8373V17.5263C22 20.6579 20 22 17 22Z"
      stroke="#E4E4FF"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9 15H15" stroke="#E4E4FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 12V18" stroke="#E4E4FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default CreatePlaylistIcon;
