import { DescriptionText, StatText, theme } from '../../../styles';

import styled from 'styled-components';

type PlayLIstCardStyleProps = {
  bgImage?: string;
};

export const StatTableStyled = styled.table`
  display: grid;
  border-collapse: collapse;
  min-width: 100%;
  height: max-content;
  grid-template-columns:
    minmax(300px, 5fr)
    minmax(60px, 1fr)
    minmax(100px, 1fr)
    minmax(100px, 1fr)
    minmax(60px, 1fr)
    minmax(100px, 1fr)
    minmax(80px, 1fr);
  gap: 10px 20px;

  thead,
  tbody,
  tr {
    display: contents;
    cursor: pointer;
  }
  th {
    margin-bottom: 10px;
    cursor: pointer;
    user-select: none;
    &:first-child {
      text-align: left;
    }
  }
  td {
    user-select: none;
    &:not(:first-child) {
      text-align: center;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

export const HeaderTitle = styled(StatText)`
  color: ${theme.colors.white.white_80};
`;

export const Inner = styled.div`
  display: flex;
  gap: 20px;
  height: 80px;
`;

export const InnerImage = styled.div<PlayLIstCardStyleProps>`
  background-color: ${theme.colors.blue.blue_20};
  background-image: url(${(props) => props.bgImage});
  background-size: cover;
  background-position: center center;
  border-radius: 20px;
  height: 80px;
  width: 115px;
  flex-shrink: 0;
`;

export const InnerText = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
  overflow: auto;
  width: 361px;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const Title = styled(DescriptionText)`
  hyphens: none;
  overflow: hidden;
  text-overflow: ellipsis; /* Добавляем многоточие */
  white-space: nowrap; //Запрещаем перенос строк
`;

export const SubTitle = styled(Title)`
  color: ${theme.colors.text.white_80};
`;
