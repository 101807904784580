const EyeIcon = () => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M10.5 7C10.5 8.38268 9.38268 9.5 8 9.5C6.61732 9.5 5.5 8.38268 5.5 7C5.5 5.61732 6.61732 4.5 8 4.5C9.38268 4.5 10.5 5.61732 10.5 7Z"
      stroke="#BBB6DA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 13.25C10.7057 13.25 13.2274 11.679 14.9826 8.95997C15.6725 7.89501 15.6725 6.10498 14.9826 5.04003C13.2274 2.321 10.7057 0.75 8 0.75C5.29433 0.75 2.77261 2.321 1.01737 5.04003C0.327542 6.10498 0.327542 7.89501 1.01737 8.95997C2.77261 11.679 5.29433 13.25 8 13.25Z"
      stroke="#BBB6DA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EyeIcon;
