const LibraryIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
    <rect x="6" y="2" width="16" height="16" rx="2" stroke="#E4E4FF" strokeWidth="2" />
    <path
      d="M13.4427 15C13.0865 15 12.7319 14.9018 12.4164 14.7074C11.8409 14.3524 11.4973 13.7388 11.4973 13.0663V9.18362C11.4973 8.72635 11.8707 8.33854 12.3314 8.33854C12.7921 8.33854 13.1655 8.72635 13.1655 9.18362V13.0661C13.1655 13.2059 13.2452 13.2737 13.2969 13.2992C13.3889 13.3447 13.4928 13.3463 13.5911 13.2671C14.3597 12.6479 16.238 11.1376 17.1259 10.4231C17.2494 10.3238 17.3288 10.2539 17.3288 10.1451C17.3288 10.0446 17.2985 9.97258 17.1555 9.89723C15.676 9.11846 12.6307 7.51413 11.0796 6.69876C10.9654 6.63877 10.8475 6.67738 10.7996 6.70694C10.7504 6.73723 10.6682 6.80798 10.6682 6.94161V7.50179C10.6682 7.95906 10.3127 8.3394 9.85217 8.3394C9.39162 8.3394 9 7.95906 9 7.50179V6.94161C9 6.26905 9.34365 5.65547 9.91909 5.30053C10.4945 4.94545 11.2107 4.89235 11.8084 5.20925C13.3515 6.02749 16.3922 7.62335 17.9277 8.42954C18.5824 8.77328 19 9.41972 19 10.1559C19 10.8613 18.5548 11.3975 18.0091 11.8391C17.0902 12.5829 15.5375 13.8397 14.6079 14.5884C14.2517 14.8753 13.7563 15 13.4427 15Z"
      fill="#E4E4FF"
    />
    <path d="M2 12V16C2 19.3137 4.68629 22 8 22H12" stroke="#E4E4FF" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

export default LibraryIcon;
