const StopWatchIcon = ({ width = 24, height = 24, ...props }: SVGProps) => (
  <svg width={width} height={height} {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5 13.7647C20.5 18.3129 16.6944 22 12 22C7.30558 22 3.5 18.3129 3.5 13.7647C3.5 9.21649 7.30558 5.52942 12 5.52942C14.2543 5.52942 16.4163 6.39706 18.0104 7.94148C19.6045 9.4859 20.5 11.5806 20.5 13.7647Z"
      stroke="#E4E4FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2141 14.3116C13.2141 15.2862 12.3987 16.0763 11.3927 16.0763C10.3868 16.0763 9.57129 15.2862 9.57129 14.3116C9.57129 13.337 10.3868 12.5469 11.3927 12.5469C12.3987 12.5469 13.2141 13.337 13.2141 14.3116Z"
      stroke="#E4E4FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.2499 5.52941C11.2499 5.94363 11.5857 6.27941 11.9999 6.27941C12.4141 6.27941 12.7499 5.94363 12.7499 5.52941H11.2499ZM12.7499 2C12.7499 1.58579 12.4141 1.25 11.9999 1.25C11.5857 1.25 11.2499 1.58579 11.2499 2H12.7499ZM16.1221 10.773C16.4196 10.4848 16.4271 10.01 16.1389 9.7125C15.8507 9.41501 15.3759 9.4075 15.0784 9.69572L16.1221 10.773ZM12.1641 12.5193C11.8666 12.8075 11.8591 13.2823 12.1473 13.5798C12.4355 13.8773 12.9104 13.8848 13.2078 13.5966L12.1641 12.5193ZM14.4285 2.75034C14.8428 2.75034 15.1785 2.41456 15.1785 2.00034C15.1785 1.58613 14.8428 1.25034 14.4285 1.25034V2.75034ZM9.5714 1.25034C9.15719 1.25034 8.8214 1.58613 8.8214 2.00034C8.8214 2.41456 9.15719 2.75034 9.5714 2.75034V1.25034ZM21.047 5.9253C21.3304 5.62324 21.3153 5.14861 21.0132 4.86518C20.7112 4.58175 20.2365 4.59686 19.9531 4.89892L21.047 5.9253ZM17.5245 7.48715C17.2411 7.78921 17.2562 8.26385 17.5583 8.54728C17.8603 8.8307 18.335 8.8156 18.6184 8.51354L17.5245 7.48715ZM4.02188 4.87357C3.72439 4.58535 3.24957 4.59286 2.96135 4.89035C2.67312 5.18784 2.68063 5.66265 2.97812 5.95088L4.02188 4.87357ZM5.52812 8.42146C5.82561 8.70969 6.30043 8.70218 6.58865 8.40469C6.87688 8.1072 6.86937 7.63239 6.57188 7.34416L5.52812 8.42146ZM12.7499 5.52941V2H11.2499V5.52941H12.7499ZM15.0784 9.69572L12.1641 12.5193L13.2078 13.5966L16.1221 10.773L15.0784 9.69572ZM14.4285 1.25034H9.5714V2.75034H14.4285V1.25034ZM19.9531 4.89892L17.5245 7.48715L18.6184 8.51354L21.047 5.9253L19.9531 4.89892ZM2.97812 5.95088L5.52812 8.42146L6.57188 7.34416L4.02188 4.87357L2.97812 5.95088Z"
      fill="#E4E4FF"
    />
  </svg>
);

export default StopWatchIcon;
