import { ButtonText } from '../../../../styles';
import { Button } from '../Button';

import styled from 'styled-components';

export const GoHomeButtonStyled = styled(Button)`
  padding: 0;
  margin: 27px 0 0 6px;
`;

export const BackLink = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const BackLinkText = styled(ButtonText)``;
