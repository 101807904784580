const CommentsIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path
      d="M4.25 4.875H11.75M4.25 8.625H8.75M5.375 13.2119H5C2 13.2119 0.5 12.4641 0.5 8.72532V4.98654C0.5 1.99551 2 0.5 5 0.5H11C14 0.5 15.5 1.99551 15.5 4.98654V8.72532C15.5 11.7164 14 13.2119 11 13.2119H10.625C10.3925 13.2119 10.1675 13.324 10.025 13.511L8.9 15.0065C8.405 15.6645 7.595 15.6645 7.1 15.0065L5.975 13.511C5.855 13.3465 5.5775 13.2119 5.375 13.2119Z"
      stroke="#BBB6DA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CommentsIcon;
