import { SwitchItem, SwitchTitle, Wrapper } from './TabsSwitcherStyle';

import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const LibraryTabsSwitcher = () => {
  const { t } = useTranslation('components');

  const location = useLocation();

  return (
    <Wrapper switchPosition={location.pathname === '/library' ? 0 : 1}>
      <Link to=".">
        <SwitchItem isActive={location.pathname === '/library'}>
          <SwitchTitle>{t('pl', { count: 101 })}</SwitchTitle>
        </SwitchItem>
      </Link>
      <Link to="./videos">
        <SwitchItem isActive={location.pathname === '/library/videos'}>
          <SwitchTitle>{t('video', { count: 101 })}</SwitchTitle>
        </SwitchItem>
      </Link>
    </Wrapper>
  );
};

export default LibraryTabsSwitcher;
